<script lang="ts">
import { useAccountStore, useBusinessUsersStore } from "@/stores";

import InputWrapper from "@/components/Input/InputWrapper.vue";

import { ACCOUNT_ID } from "@/helpers";

export default {
  components: {
    InputWrapper,
  },
  data() {
    return {
      accountStore: useAccountStore(),
      businessUsersStore: useBusinessUsersStore(),
      isChangeEmailPopupVisible: this.visible,
      newEmail: "",
      accountId: ACCOUNT_ID,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    businessUserEmail: {
      type: String,
      required: false,
    },
    businessUserId: {
      type: String,
      required: false,
    },
  },
  watch: {
    // Watch for changes to the 'visible' prop from the parent component
    visible(value) {
      this.isChangeEmailPopupVisible = value;
    },
  },
  created() {
    this.accountId = localStorage.getItem("accountId") ?? undefined; // Workaround, need to have this
    this.newEmail = this.businessUserEmail ?? this.accountStore.user.email;
  },
  methods: {
    emitClosePopup() {
      this.$emit("close-edit-dialog", this.isChangeEmailPopupVisible);
      this.isChangeEmailPopupVisible = false;
    },
    async updateEmail() {
      this.businessUserEmail
        ? await this.updateBusinessEmail()
        : await this.updateStandardEmail();
    },
    async updateStandardEmail() {
      await this.accountStore
        .updateEmail(this.accountId, this.newEmail)
        .then(() => {
          this.isChangeEmailPopupVisible = false;
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Email is updated!",
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 5000,
          });
        });
    },
    async updateBusinessEmail() {
      try {
        if (!this.businessUserId) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: `Failed to update email. Try again`,
            life: 5000,
          });
          this.isChangeEmailPopupVisible = false;
          return;
        }

        await this.businessUsersStore
          .updateBusinessUser(this.businessUserId, { email: this.newEmail })
          .then(() => {
            this.isChangeEmailPopupVisible = false;
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: `Email is updated to ${this.newEmail}.`,
              life: 5000,
            });
          });
      } catch (error) {
        console.error("Error updating setting:", error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to update email. Error: ${error.message}`,
          life: 5000,
        });
      }
    },
  },
  computed: {
    userEmail() {
      return this.businessUserEmail ?? this.accountStore.user.email;
    },
  },
};
</script>

<template>
  <PVPopup
    v-model:visible="isChangeEmailPopupVisible"
    modal
    dismissableMask
    :draggable="false"
    header="Change Email"
    @hide="emitClosePopup"
  >
    <form id="change-email-form" @submit.prevent="updateEmail">
      <div class="input-container">
        <InputWrapper
          labelText="Email"
          labelRef="email"
          v-model="this.newEmail"
          variant="grey"
        />
      </div>
    </form>
    <template #footer>
      <div class="btn-container">
        <PVButton
          class="btn btn-secondary"
          label="Cancel"
          @click="emitClosePopup"
          text
        />
        <PVButton
          class="btn btn-primary"
          label="Save"
          type="submit"
          form="change-email-form"
          autofocus
        />
      </div>
    </template>
  </PVPopup>
</template>
