<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.280803 8.67966C0.102455 8.50388 -2.38419e-07 8.25779 -2.38419e-07 7.99998C-2.38419e-07 7.74216 0.102455 7.49998 0.280803 7.32029L6.95937 0.757788C7.32366 0.398413 7.90045 0.414038 8.24576 0.789038C8.59107 1.16404 8.57969 1.75779 8.2154 2.11326L3.1837 7.06248H16.0893C16.594 7.06248 17 7.48044 17 7.99998C17 8.51951 16.594 8.93748 16.0893 8.93748H3.1837L8.2192 13.8828C8.58348 14.2422 8.59487 14.832 8.24955 15.207C7.90424 15.582 7.32746 15.5937 6.96317 15.2383L0.284598 8.67576L0.280803 8.67966Z"
    />
  </svg>
</template>
