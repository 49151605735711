import { createRouter, createWebHistory } from "vue-router";
import { useAccountStore } from "@/stores";

import { ACCOUNT_ID } from "@/helpers";

import { BASE_URL, isCurrentRouteAPublicRoute } from "@/helpers";

import NotFoundGeneralView from "@/views/error/404/NotFoundGeneralView.vue";

import CardPrivateView from "@/views/CardPrivateView.vue";
import CardPublicView from "@/views/CardPublicView.vue";

// Business Routes
import DashboardView from "@/views/business/DashboardView.vue";
import UsersView from "@/views/business/UsersView.vue";
import UserDetailView from "@/views/business/UserDetailView.vue";
import CardView from "@/views/business/CardsView.vue";
import FieldsView from "@/views/business/FieldsView.vue";
import ConnectionsView from "@/views/business/ConnectionsView.vue";
import ConnectionDetailsView from "@/views/business/ConnectionDetailsView.vue";
import PagePrivateView from "@/views/business/PagePrivateView.vue";
import SettingsView from "@/views/business/SettingsView.vue";

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/account" }, // Problem with account
    {
      path: "/account",
      name: "account",
      component: () => import("@/views/AccountView.vue"),
      meta: {
        pageTitle: "Account",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/LoginView.vue"),
      meta: {
        pageTitle: "Login",
      },
    },
    {
      path: "/sign-up",
      name: "email",
      component: () => import("@/views/auth/signup/EmailView.vue"),
      meta: {
        pageTitle: "Signup",
      },
    },
    {
      path: "/sign-up",
      name: "name",
      component: () => import("@/views/auth/signup/NameView.vue"),
      meta: {
        pageTitle: "Signup",
      },
    },
    {
      path: "/sign-up",
      name: "password",
      component: () => import("@/views/auth/signup/PasswordView.vue"),
      meta: {
        pageTitle: "Signup",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/auth/PassForgotView.vue"),
      meta: {
        pageTitle: "Forgot Password",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/auth/PassResetView.vue"),
      meta: {
        pageTitle: "Reset Password",
      },
    },
    {
      path: "/verify-email",
      name: "verify-email",
      component: () => import("@/views/auth/VerifyEmailView.vue"),
      meta: {
        pageTitle: "Verify Email",
      },
    },
    {
      path: "/account-verified",
      name: "account-verified",
      component: () => import("@/views/auth/AccountVerifiedView.vue"),
    },
    {
      path: "/p/:id([a-zA-Z]{8})",
      name: "page",
      component: () => import("@/views/PagePublicView.vue"),
    },
    {
      path: "/p/:id/save",
      name: "save-page",
      component: () => import("@/views/PagePublicView.vue"),
      beforeEnter: async (to, from, next) => {
        const url = `${BASE_URL}/page/public/${to.params.id}`;

        const response = await fetch(url);
        const pageData = await response.json();

        const isBusinessPage = pageData.msg === "Page not found";

        try {
          const link = document.createElement("a");

          const standardUrl = `${BASE_URL}/page/${to.params.id}/save`;
          const businessUrl = `${BASE_URL}/page/save/${to.params.id}/`;

          link.href = isBusinessPage ? businessUrl : standardUrl;
          link.download = "contact.vcf";
          link.click();

          next(`/p/${to.params.id}`);
        } catch (error) {
          console.error("Error fetching data:", error);
          next("/404"); // Assuming you have a route defined for '/error'
          return;
        }
      },
    },
    {
      path: "/account/card/:id([a-zA-Z]{8})",
      name: "card",
      component: () => import("@/views/CardPrivateView.vue"),
      meta: {
        pageTitle: "Card",
      },
    },
    {
      path: "/wallet/:cardId([a-zA-Z]{8})",
      name: "wallet-card",
      component: () => CardPrivateView,
      beforeEnter: async (to, from, next) => {
        const cardId = to.params.cardId;
        const isAppleDevice = /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
        const isMobileDevice =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        const deviceType = isAppleDevice ? "apple" : "google";

        if (!isMobileDevice) {
          next(`/account/card/${cardId}`);
          return;
        }

        try {
          const url = `${BASE_URL}/card/${deviceType}/wallet/${cardId}`;

          if (isAppleDevice) {
            window.location.href = url;
          } else {
            const response = await fetch(url);
            const result = await response.json();
            window.open(result.data.link, "_blank");
          }
        } catch (error) {
          console.log(error);
          next("/404");
        }

        next(`/account/card/${cardId}`);
      },
    },
    {
      path: "/wallet/:cardId([a-zA-Z]{8})/:businessId([a-zA-Z]{8})",
      name: "wallet-card-business",
      component: () => CardPublicView,
      beforeEnter: async (to, from, next) => {
        const cardId = to.params.cardId;
        const businessId = to.params.businessId;
        const isMobileDevice =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        const isAppleDevice = /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
        const deviceType = isAppleDevice ? "apple" : "google";

        if (!isMobileDevice) {
          next(`/wallet/${cardId}/${businessId}/scan`);
          return;
        }

        try {
          const url = `${BASE_URL}/card/${deviceType}/wallet/${cardId}/${businessId}`;

          if (isAppleDevice) {
            window.location.href = url;
          } else {
            const response = await fetch(url);
            const result = await response.json();
            window.open(result.data.link, "_blank");
          }
        } catch (error) {
          console.log(error);
          next("/404");
        }

        next(`add-virtual-card/${cardId}/${businessId}`);
      },
    },
    {
      path: "/wallet/:cardId([a-zA-Z]{8})/:businessId([a-zA-Z]{8})/scan",
      name: "wallet-scan",
      component: () => import("@/views/business/WalletScanView.vue"),
    },
    {
      path: "/c/:id",
      component: () => import("@/views/CardPublicView.vue"),
      beforeEnter: async (to, from, next) => {
        const cardId = to.params.id;

        try {
          const response = await fetch(`${BASE_URL}/card/${cardId}`);

          if (!response.ok) {
            throw new Error(
              `Error: ${response.status} - ${response.statusText}`
            );
          }

          const cardData = await response.json();
          const pageId = cardData?.data?.pageId ?? null;

          if (!pageId) {
            next();
          } else {
            next(`/p/${pageId}`);
          }
        } catch (error) {
          console.error("Error fetching card data:", error.message);
          next(new Error("An error occurred while fetching card data"));
        }
      },
    },
    {
      path: "/account/settings",
      name: "account-settings",
      component: () => import("@/views/AccountSettingsView.vue"),
      meta: {
        pageTitle: "Settings",
      },
    },
    {
      path: "/account/page/:id([a-zA-Z]{8})",
      name: "edit",
      component: () => import("@/views/PagePrivateView.vue"),
      meta: {
        pageTitle: "Page",
      },
    },
    {
      path: "/account/connections",
      name: "connections",
      component: () => import("@/views/ConnectionsView.vue"),
      meta: {
        pageTitle: "Connections",
      },
    },
    {
      path: "/account/connections/connection/:connectId([a-zA-Z]{8})",
      name: "connection",
      component: () => import("@/views/ConnectionDetailsView.vue"),
      meta: {
        pageTitle: "Connection",
      },
    },
    {
      path: "/business/sign-up",
      name: "business-signup",
      component: () => import("@/views/business/auth/SignupView.vue"),
      meta: {
        pageTitle: "Signup",
      },
    },
    {
      path: "/business/dashboard",
      name: "business-dashboard",
      component: DashboardView,
      meta: {
        pageTitle: "Dashboard",
      },
    },
    {
      path: "/business/users",
      name: "business-users",
      component: UsersView,
      meta: {
        pageTitle: "Users",
      },
    },
    {
      path: "/business/users/user/:userId",
      name: "business-users-detail",
      component: UserDetailView,
      meta: {
        pageTitle: "User",
      },
    },
    {
      path: "/business/cards",
      name: "business-cards",
      component: CardView,
      meta: {
        pageTitle: "Cards",
      },
    },
    {
      path: "/business/fields",
      name: "business-fields",
      component: FieldsView,
      meta: {
        pageTitle: "Fields",
      },
    },
    {
      path: "/business/cards/card/:id",
      name: "business-card-detail-private",
      component: CardPrivateView,
      meta: {
        pageTitle: "Card",
      },
    },
    {
      path: "/business/connections",
      name: "business-connection",
      component: ConnectionsView,
      meta: {
        pageTitle: "Connections",
      },
    },
    {
      path: "/business/connections/connection/:connectionId",
      name: "business-connection-detail",
      component: ConnectionDetailsView,
      meta: {
        pageTitle: "Connection",
      },
    },
    {
      path: "/business/page",
      name: "business-page",
      component: PagePrivateView,
      meta: {
        pageTitle: "Page",
      },
    },
    {
      path: "/business/settings",
      name: "business-settings",
      component: SettingsView,
      meta: {
        pageTitle: "Settings",
      },
    },
    {
      path: "/add-virtual-card/:cardId([a-zA-Z]{8})/:businessId([a-zA-Z]{8})",
      name: "virtual-card",
      component: () => import("@/views/AddVirtualCard.vue"),
      meta: {
        pageTitle: "Virtual Card",
      },
    },
    {
      path: "/super-admin",
      name: "super-admin",
      component: () => import("@/views/super-admin/SuperAdmin.vue"),
      beforeEnter: (to, from, next) => {
        const accountStore = useAccountStore();
        if (!accountStore.user.isSuperAdmin) next("/");
        next();
      },
    },
    {
      path: "/404",
      name: "404",
      component: NotFoundGeneralView,
      meta: {
        pageTitle: "404",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "Not found",
      component: NotFoundGeneralView,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const pageTitle = to.meta.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (pageTitle) {
    document.title = `${pageTitle} - ConnectFlow`;
  }
  // Continue resolving the route
  next();
});

router.beforeEach(async (to) => {
  const accountStore = useAccountStore();

  const publicPages = [
    "/login",
    "/reset-password",
    "/forgot-password",
    "/verify-email",
    "/account-verified",
    "/business/sign-up",
    "/sign-up",
    `/p/${to.params.id}`,
    `/p/${to.params.id}/save`,
    `/c/${to.params.id}`,
    `/wallet/${to.params.cardId}`,
    `/wallet/${to.params.cardId}/${to.params.businessId}`,
    `/wallet/${to.params.cardId}/${to.params.businessId}/scan`,
    `/add-virtual-card/${to.params.cardId}/${to.params.businessId}`,
    "/404",
  ];

  const authRequired = !publicPages.includes(to.path);

  const currentPathname = window.location.pathname;
  const isUserOnPublicRoute = isCurrentRouteAPublicRoute(currentPathname);

  // Check if user data is already available
  if (!accountStore.user && !isUserOnPublicRoute) {
    // If it's not, fetch it
    if (ACCOUNT_ID !== "0" && ACCOUNT_ID !== "undefined") {
      try {
        await accountStore.getAccountData(ACCOUNT_ID);
      } catch (error) {
        console.error(`Error in fetching account: ${error}`);
      }
    }
  }

  // Check again after possibly fetching user data
  if (authRequired && !accountStore.user) {
    accountStore.returnUrl = to.fullPath;
    return "/login";
  }
});

export default router;
