<template>
  <section class="inner-container">
    <h1>{{ title }}</h1>

    <ul class="pageitem-container">
      <slot />
    </ul>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
section {
  background-color: transparent;
  display: flex;
  justify-content: center;

  .pageitem-container {
    padding: 0 20px;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    max-width: 500px;
    transition: 0.2s;
  }
}
</style>
