<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8086 7.46484C14.0656 7.20781 14.0656 6.79219 13.8086 6.53789L10.3086 3.03516C10.0516 2.77813 9.63594 2.77813 9.38164 3.03516C9.12734 3.29219 9.12461 3.70781 9.38164 3.96211L11.7605 6.34102L5.03125 6.34375C4.66758 6.34375 4.375 6.63633 4.375 7C4.375 7.36367 4.66758 7.65625 5.03125 7.65625H11.7605L9.38164 10.0352C9.12461 10.2922 9.12461 10.7078 9.38164 10.9621C9.63867 11.2164 10.0543 11.2191 10.3086 10.9621L13.8086 7.46484ZM4.59375 2.1875C4.95742 2.1875 5.25 1.89492 5.25 1.53125C5.25 1.16758 4.95742 0.875 4.59375 0.875H2.40625C1.07734 0.875 0 1.95234 0 3.28125V10.7188C0 12.0477 1.07734 13.125 2.40625 13.125H4.59375C4.95742 13.125 5.25 12.8324 5.25 12.4688C5.25 12.1051 4.95742 11.8125 4.59375 11.8125H2.40625C1.80195 11.8125 1.3125 11.323 1.3125 10.7188V3.28125C1.3125 2.67695 1.80195 2.1875 2.40625 2.1875H4.59375Z"
      fill="#222222"
    />
  </svg>
</template>
