<script lang="ts">
import { useAccountStore, useBusinessSettingsStore } from "@/stores";

export default {
  data() {
    return {
      accountStore: useAccountStore(),
      businessSettingsStore: useBusinessSettingsStore(),
      businessId: "",
    };
  },
  async created() {
    if (!this?.accountStore?.user?.isBusinessUser) {
      this.$router.push("/account");
    }

    this.businessId = await this?.accountStore?.user?.belongToBusinessObjId;

    if (!this.businessId) {
      return;
    }

    if (this.accountStore.isUserAdmin) {
      await this.businessSettingsStore.getBusinessStatistics(this.businessId);
    }
  },
  computed: {
    connectionsCount() {
      if (this.accountStore.isUserAdmin) {
        return (
          this?.businessSettingsStore?.businessStatistics?.connectCount ?? 0
        );
      } else {
        return this?.accountStore?.user?.numberOfConnections ?? 0;
      }
    },
  },
};

</script>

<template>
  <div class="container">
    <h1 class="dashboard-heading">
      Welcome {{ this.accountStore.user.fname }} 👋
    </h1>

    <section>
      <h1>Insights</h1>
      <div class="content">
        <!-- <p>
          2300
          <span>Shares</span>
        </p>
        <p>
          130
          <span>Saves</span>
        </p> -->
        <p>
          {{ this.connectionsCount ?? 0 }}
          <span v-if="this.connectionsCount === 1">Connection</span>
          <span v-else>Connections</span>
        </p>
        <p v-if="this.accountStore.isUserAdmin">
          {{ this.businessSettingsStore.businessStatistics.users ?? 0 }}
          <span v-if="this.businessSettingsStore.businessStatistics.users === 1"
            >User</span
          >
          <span v-else>Users</span>
        </p>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
section {
  width: 100%;
  max-width: 600px;
  margin-top: 48px;
  animation: move-up-50 .8s cubic-bezier(0,0,0,1) forwards;

  h1 {
    color: var(--color-text-grey);
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 13px;
    top: -24px;
    padding-left: 10px;
    position: absolute;
  }

  .content {
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.2s;

    p {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 3px;
      align-items: center;
      padding-bottom: 0px;
      width: 100%;
      line-height: initial;

      span {
        color: var(--color-text-grey);
        font-weight: 500;
        font-size: 14px;
        display: block;
      }
    }
  }
}

.dashboard-heading {
  margin-top: 32px;
  animation: move-up-100 .8s cubic-bezier(0,0,0,1) forwards;
}
</style>

