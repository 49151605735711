<script lang="ts">
import { usePagesStore } from "@/stores";

import InputWrapper from "@/components/Input/InputWrapper.vue";
import InputTextArea from "@/components/Input/InputTextArea.vue";
import { getPageId } from "@/helpers";

export default {
  components: {
    InputWrapper,
    InputTextArea,
  },
  data() {
    return {
      pageId: "",
      pagesStore: usePagesStore(),
      isEditDialogVisible: this.visible,
      isAddItemDialogVisible: false,
      isAddItemSubDialogVisible: false,
      isAddItemSubDialogAddressVisible: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    // Watch for changes to the 'visible' prop from the parent component
    visible(value) {
      this.isEditDialogVisible = value;
    },
  },
  created() {
    this.pageId = getPageId(this.$route);
  },
  methods: {
    emitClosePopup() {
      this.$emit("close-edit-dialog", this.isEditDialogVisible);
      this.isEditDialogVisible = false;
    },
    async onUpload(event) {
      const file = await event.files[0];

      try {
        await this.pagesStore
          .uploadProfilePicture(file, this.pageId.toString())
          .then(() => {
            this.isEditDialogVisible = false;
            location.reload();
          });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `File failed to upload: ${error}`,
          life: 5000,
        });
      }
    },
    async updatePage() {
      if (this.pagesStore.pages && this.pagesStore.pages[0]) {
        const { fname, lname, companyName, companyFunction, bio } =
          this.pagesStore.pages[0];

        await this.pagesStore
          .updatePage(this.pageId, {
            fname,
            lname,
            company: companyName,
            companyFunction,
            bio,
          })
          .then(() => {
            this.isEditDialogVisible = false;
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Page is updated",
              life: 5000,
            });
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: "Failed",
              detail: `Something went wrong: ${error}`,
              life: 5000,
            });
          });
      } else {
        // Handle the case when this.pagesStore[0] is null
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: "Something went wrong with updating the page",
          life: 5000,
        });
      }
    },
  },
};
</script>

<template>
  <PVPopup
    class="popup"
    v-model:visible="isEditDialogVisible"
    modal
    dismissableMask
    :draggable="false"
    header="General"
    @hide="emitClosePopup"
  >
    <form id="edit-page-data" @submit.prevent="updatePage">
      <div class="card flex justify-content-center">
        <PVFileUpload
          class="btn btn-primary"
          mode="basic"
          name="demo[]"
          accept="image/*"
          :auto="true"
          chooseLabel="Upload image"
          @uploader="onUpload"
          customUpload
        />
        <!--
        <small>We suggest a squared image</small>
        -->
      </div>

      <div class="input-container">
        <InputWrapper
          labelText="First name"
          labelRef="firstName"
          v-model="this.pagesStore.pages[0].fname"
          variant="grey"
        />

        <InputWrapper
          labelText="Last name"
          labelRef="lastName"
          v-model="this.pagesStore.pages[0].lname"
          variant="grey"
        />

        <InputWrapper
          labelText="Function"
          labelRef="companyFunction"
          v-model="this.pagesStore.pages[0].companyFunction"
          variant="grey"
        />

        <InputWrapper
          labelText="Company"
          labelRef="company"
          v-model="this.pagesStore.pages[0].companyName"
          variant="grey"
        />

        <InputTextArea
          labelText="Bio"
          labelRef="bio"
          v-model="this.pagesStore.pages[0].bio"
          variant="grey"
        />
      </div>
    </form>
    <template #footer>
      <div class="btn-container">
        <PVButton
          class="btn btn-secondary"
          label="Cancel"
          @click="emitClosePopup"
          text
        />
        <PVButton
          class="btn btn-primary"
          label="Save"
          autofocus
          form="edit-page-data"
          type="submit"
        />
      </div>
    </template>
  </PVPopup>
</template>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}
</style>
