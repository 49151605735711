<template>
  <svg
    width="172"
    height="172"
    viewBox="0 0 172 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86 16.125C104.532 16.125 122.305 23.4868 135.409 36.5909C148.513 49.695 155.875 67.468 155.875 86C155.875 104.532 148.513 122.305 135.409 135.409C122.305 148.513 104.532 155.875 86 155.875C67.468 155.875 49.695 148.513 36.5909 135.409C23.4868 122.305 16.125 104.532 16.125 86C16.125 67.468 23.4868 49.695 36.5909 36.5909C49.695 23.4868 67.468 16.125 86 16.125ZM86 172C108.809 172 130.683 162.939 146.811 146.811C162.939 130.683 172 108.809 172 86C172 63.1914 162.939 41.3169 146.811 25.1888C130.683 9.06069 108.809 0 86 0C63.1914 0 41.3169 9.06069 25.1888 25.1888C9.06069 41.3169 0 63.1914 0 86C0 108.809 9.06069 130.683 25.1888 146.811C41.3169 162.939 63.1914 172 86 172ZM123.961 70.2109C127.119 67.0531 127.119 61.9469 123.961 58.8227C120.803 55.6984 115.697 55.6648 112.573 58.8227L75.2836 96.1117L59.4945 80.3227C56.3367 77.1648 51.2305 77.1648 48.1063 80.3227C44.982 83.4805 44.9484 88.5867 48.1063 91.7109L69.6062 113.211C72.7641 116.369 77.8703 116.369 80.9945 113.211L123.961 70.2109Z"
      fill="#22C55E"
    />
  </svg>
</template>
