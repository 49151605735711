import { defineStore } from "pinia";

import { fetchWrapper, BASE_URL } from "@/helpers";

import Cookies from "universal-cookie";

import type {
  BusinessSettings,
  BusinessSettingsState,
  BusinessStatistics,
} from "./types";

const cookies = new Cookies();

export const useBusinessSettingsStore = defineStore({
  id: "business-settings",
  state: (): BusinessSettingsState => ({
    businessSettings: {} as BusinessSettings,
    businessStatistics: {} as BusinessStatistics,
  }),
  actions: {
    async getBusinessSettings(businessId: string): Promise<void> {
      const settings = await fetchWrapper.get(
        `${BASE_URL}/settings/${businessId}`
      );

      this.businessSettings = settings;

      return settings;
    },
    async setBusinessSettings(
      businessId: string,
      data: BusinessSettings
    ): Promise<void> {
      const response = await fetchWrapper.post(
        `${BASE_URL}/settings/${businessId}`,
        data
      );

      // TODO: Backend - Workaround, because POST endpoint does not return updated settings
      this.businessSettings = await this.getBusinessSettings(businessId);

      return response;
    },
    async uploadBusinessAssetType(
      businessId: string,
      assetType: string,
      file: File
    ) {
      const formData = new FormData();
      formData.append("image", file);

      //   cdnWrapper.post(`${BASE_URL}/pageimage/upload/${pageId}`, {
      //     formData,
      //   });

      const authHeader = {
        Authorization: "jwt " + cookies.get("token"),
      };

      try {
        const response = await fetch(
          `${BASE_URL}/business/upload/${businessId}/${assetType}`,
          {
            method: "POST",
            body: formData,
            headers: {
              ...authHeader,
            },
          }
        );

        return await response.json();

        // if (response.ok) {
        //   const responseData = await response.json();
        //   console.log("Image uploaded successfully:", responseData);
        // } else {
        //   const errorData = await response.json();
        //   console.error("Error uploading image:", errorData);
        // }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    async getBusinessStatistics(businessId: string) {
      const businessStatistics = await fetchWrapper.get(
        `${BASE_URL}/dashboard/${businessId}`
      );

      this.businessStatistics = businessStatistics;

      return businessStatistics;
    },
  },
});
