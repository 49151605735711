<script>
import InputWrapper from "@/components/Input/InputWrapper.vue";
import IconUser from "@/components/icons/IconUser.vue";

import {
  useAuthStore,
  useAccountStore,
  useBusinessSettingsStore,
} from "@/stores";

export default {
  data() {
    return {
      showUpdateSubdomainPopup: false,
      authStore: useAuthStore(),
      accountStore: useAccountStore(),
      businessSettingsStore: useBusinessSettingsStore(),
      businessId: "",
      updatedSettings: {},
    };
  },
  components: {
    InputWrapper,
    IconUser,
  },
  created() {
    this.businessId = this.accountStore.user.belongToBusinessObjId;
  },
  methods: {
    logout() {
      this.authStore.logout();
    },
    async updateSetting(input) {
      const value = input.target.value;
      const property = input.target.id;
      const labelName = input.target.previousSibling.innerHTML;

      const updatedSetting = {};

      updatedSetting[property] = value;

      // Workaround for setting company name also in account
      if (property === "companyName") {
        const firstName = this.accountStore.user.fname;
        const lastName = this.accountStore.user.lname;
        const companyName = value;
        this.accountStore.updateSettings(firstName, lastName, companyName);
      }

      this.businessSettingsStore
        .setBusinessSettings(this.businessId, updatedSetting)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: `${labelName} is updated to ${value}.`,
            life: 5000,
          });
        });
    },
    async updateSubdomain(input) {
      const value = input.target[0].value;
      const property = input.target[0].id;
      const labelName = input.target[0].previousSibling.innerHTML;

      const updatedSetting = {};

      updatedSetting[property] = value;

      this.$confirm.require({
        message: "Are you sure you want to update your subdomain?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessSettingsStore
            .setBusinessSettings(this.businessId, updatedSetting)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: `${labelName} is updated to ${value}.`,
                life: 5000,
              });
            });
          this.showUpdateSubdomainPopup = false;
          setTimeout(() => {
            location.reload();
          }, 0);
        },
      });
    },
  },
  computed: {
    authenticatedFullName() {
      if (!this.accountStore.user) {
        return "";
      }
      const firstName = this.accountStore.user.fname;
      const lastName = this.accountStore.user.lname;

      return firstName + " " + lastName;
    },
  },
};
</script>

<template>
  <div class="business-card" v-if="authenticatedFullName">
    <div class="image">
      <IconUser />
    </div>
    <div class="text">
      <p class="title">Logged in as</p>
      <p class="subtitle">{{ authenticatedFullName }}</p>
    </div>
    <button class="action" @click="logout">
      <i class="pi pi-sign-out" />
    </button>
  </div>
  <InputWrapper
    labelText="Company name"
    labelRef="companyName"
    placeholder="ConnectFlow"
    v-model="this.businessSettingsStore.businessSettings.companyName"
    @change="updateSetting"
  />

  <PVButton
    class="btn btn-primary"
    @click="this.showUpdateSubdomainPopup = true"
    >Update subdomain</PVButton
  >

  <PVPopup
    v-if="showUpdateSubdomainPopup"
    modal
    :dismissableMask="true"
    :draggable="false"
    v-model:visible="showUpdateSubdomainPopup"
    header="Update subdomain"
    class="popup1"
  >
    <form id="update-subdomain-form" @submit.prevent="updateSubdomain">
      <InputWrapper
        labelText="Subdomain"
        labelRef="subdomain"
        v-model="this.businessSettingsStore.businessSettings.subdomain"
        placeholder="businesscard.connectflow.io"
        variant="grey"
      />
    </form>

    <template #footer>
      <div class="btn-container">
        <PVButton
          class="btn btn-grey"
          label="Cancel"
          @click="emitClosePopup"
          text
        />
        <PVButton
          class="btn btn-primary"
          type="submit"
          label="Save"
          form="update-subdomain-form"
          autofocus
        />
      </div>
    </template>
  </PVPopup>
</template>

<style lang="scss" scoped>
.business-card {
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  border-radius: 6px;
  align-items: center;
  padding: 12px 14px 14px 12px;
  gap: 10px;
  margin-bottom: 10px;

  .action {
    position: absolute;
    right: 20px;
  }
}

.image {
  display: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey);
  border-radius: 50%;
}

.text p {
  margin: 0;
  line-height: 1.25em;
}

p.title {
  font-size: 17.5px;
  font-weight: 600;
  color: #222; // Update this globally
}

p.subtitle {
  color: #222222bf;
  font-size: 14px;
  font-weight: normal;
}

button.action {
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

.popup1 {
  border: 1px solid red;
}
</style>
