import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import "primeicons/primeicons.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import * as Sentry from "@sentry/vue";

// Vue
import App from "./App.vue";
import router from "./router";

// PrimeVue Components
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import ColorPicker from "primevue/colorpicker";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileupload";
import Toolbar from "primevue/toolbar";
import TextArea from "primevue/textarea";
import InputWrapper from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import ConfirmDialog from "primevue/confirmdialog";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";
import Skeleton from "primevue/skeleton";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";

const app = createApp(App);
import "./assets/styles/main.css";

app.use(createPinia());
app.use(router);

app.use(PrimeVue);
app.use(createHead());
app.use(ToastService);
app.use(ConfirmationService);

// directives

app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Sentry.init({
  app,
  dsn: "https://14f9536428a992dec65a6b6d80be5874@o4506387910098944.ingest.sentry.io/4506389788557312",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["/^https://connectflow.app/"],
    }),
  ],
  tracesSampleRate: 1.0,
});

// Components (PV prefix)
app.component("PVButton", Button);
app.component("PVCheckbox", Checkbox);
app.component("PVColumn", Column);
app.component("PVColorPicker", ColorPicker);
app.component("PVConfirmDialog", ConfirmDialog);
app.component("PVFileUpload", FileUpload);
app.component("PVToolbar", Toolbar);
app.component("PVTextArea", TextArea);
app.component("PVInputText", InputWrapper);
app.component("PVInputSwitch", InputSwitch);
app.component("PVPopup", Dialog);
app.component("PVToast", Toast);
app.component("PVProgressSpinner", ProgressSpinner);
app.component("PVPassword", Password);
app.component("PVSkeleton", Skeleton);
app.mount("#app");
