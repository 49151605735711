<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56645 11.0732L0.892619 13.3613L0.529338 14.5977C0.456096 14.8467 0.523479 15.1133 0.705119 15.2949C0.88676 15.4766 1.15336 15.5439 1.39946 15.4736L2.63871 15.1074L4.9268 14.4336C5.29008 14.3281 5.62113 14.1436 5.90531 13.8945C5.94633 13.8594 5.98442 13.8213 6.0225 13.7832L14.9346 4.87402C15.5762 4.23242 15.6553 3.24512 15.1748 2.51562C15.1075 2.41309 15.0254 2.31348 14.9346 2.22266L13.7803 1.06543C13.0479 0.333008 11.8614 0.333008 11.1289 1.06543L2.21684 9.97754C1.91215 10.2822 1.68949 10.6602 1.56645 11.0732ZM2.9141 11.4688C2.96391 11.3047 3.04594 11.1523 3.1602 11.0234C3.17778 11.0059 3.19242 10.9883 3.21 10.9707L9.90434 4.2793L11.7207 6.0957L5.02934 12.79C4.89164 12.9277 4.71879 13.0303 4.53129 13.0859L3.84574 13.2881L2.24028 13.7598L2.71196 12.1543L2.9141 11.4688Z"
    />
  </svg>
</template>
