<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.06643 0.720313C4.75323 0.426562 4.24677 0.426562 3.9369 0.720313L0.734902 3.72031C0.421699 4.01406 0.421699 4.48906 0.734902 4.77969C1.0481 5.07031 1.55456 5.07344 1.86443 4.77969L3.697 3.06094V13.9422L1.86776 12.2203C1.55456 11.9266 1.0481 11.9266 0.738234 12.2203C0.428363 12.5141 0.425031 12.9891 0.738234 13.2797L3.9369 16.2797C4.2501 16.5734 4.75656 16.5734 5.06643 16.2797L8.2651 13.2797C8.5783 12.9859 8.5783 12.5109 8.2651 12.2203C7.9519 11.9297 7.44544 11.9266 7.13557 12.2203L5.303 13.9391V3.06094L7.13557 4.77969C7.44877 5.07344 7.95523 5.07344 8.2651 4.77969C8.57497 4.48594 8.5783 4.01094 8.2651 3.72031L5.06643 0.720313Z"
    />
  </svg>
</template>
