<script lang="ts">
import { useAccountStore, useBusinessSettingsStore } from "@/stores";

export default {
  data() {
    return {
      showSaveButton: true,
      showConnectButton: true,
      accountStore: useAccountStore(),
      businessSettingsStore: useBusinessSettingsStore(),
      businessId: "",
      updatedSettings: {},
    };
  },
  created() {
    this.businessId = this.accountStore.user.belongToBusinessObjId;
    this.setInitialContent();
  },
  methods: {
    async updateButtonSetting(property: string, labelName: string) {
      const value = this[property];
      const updatedSetting: { [key: string]: any } = {};
      updatedSetting[property] = value;

      this.businessSettingsStore
        .setBusinessSettings(this.businessId, updatedSetting)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: `${labelName} is updated.`,
            life: 5000,
          });
        });
    },

    async setInitialContent() {
      const businessSettings =
        await this.businessSettingsStore.getBusinessSettings(this.businessId);
      this.showSaveButton = businessSettings.showSaveButton;
      this.showConnectButton = businessSettings.showConnectButton;
    },
  },
};
</script>

<template>
  <div>
    <div class="content-card">
      <input
        id="showSaveButton"
        name="Save Button"
        type="checkbox"
        v-model="showSaveButton"
        @change="updateButtonSetting('showSaveButton', 'Show Save Button')"
      />
      <label for="showSaveButton">Show Save button</label>
    </div>
    <div class="content-card">
      <input
        type="checkbox"
        id="showConnectButton"
        name="Connect Button"
        v-model="showConnectButton"
        @change="
          updateButtonSetting('showConnectButton', 'Show Connect Button')
        "
      />

      <label for="showConnectButton" class="title">Show Connect button</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-card {
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  border-radius: 6px;
  align-items: center;
  padding: 10px 18px;
  gap: 10px;
}

.text p {
  margin: 0;
  line-height: 1.25em;
}

label {
  font-size: 17.5px;
  font-weight: 600;
  color: #222;
}
</style>
