import { defineStore } from "pinia";
import { useAccountStore } from "./account.store";

import { cdnWrapper, fetchWrapper, BASE_URL } from "@/helpers";

import type {
  PagesState,
  Page,
  PageItem,
  UpdatePageData,
  UpdatePageItemData,
  CreatePageResponse,
} from "./types";

export const usePagesStore = defineStore({
  id: "pages",
  state: (): PagesState => ({
    pages: [] as Page[],
    pageItems: [] as PageItem[],
  }),
  actions: {
    async getPage(pageId: string, isPublic: boolean) {
      const isPagePresent = this.pages.some((page: Page) => page.id === pageId);

      const pageType = isPublic ? "public" : "private";

      if (isPagePresent) {
        // Return the found page
        return this.pages.find((page: Page) => page.id === pageId);
      }

      try {
        const page = await fetchWrapper.get(
          `${BASE_URL}/page/${pageType}/${pageId}`
        );

        // Push the page to the pages array
        this.pages.push(page);
        return page;
      } catch (error) {
        if (error === "Page not found") {
          try {
            return this.getBusinessPage(pageId);
          } catch (error) {
            window.location = "/404";
          }
        } else {
          console.error("Error getting page:", error);
        }
      }
    },
    async getBusinessPage(pageId: string) {
      try {
        const page = await fetchWrapper.get(
          `${BASE_URL}/business/page/${pageId}`
        );

        // Push the page to the pages array
        this.pages.push(page);
        return page;
      } catch (error) {
        if (error === "User not found") {
          window.location = "/404";
        }

        console.error("Error getting page:", error);
      }
    },
    // Shares doesn't increase every request
    async getPrivatePage(pageId: string) {
      return this.getPage(pageId, false);
    },
    // Shares does increase every request
    async getPublicPage(pageId: string) {
      return this.getPage(pageId, true);
    },
    async getAllPages(accountId: string) {
      const { rows } = await fetchWrapper.get(
        `${BASE_URL}/page/list/${accountId}`
      );

      this.pages = rows;

      return rows;
    },
    async updateSettings(fname: string, lname: string, companyName: string) {
      const userId = this.$state?.user?.accountId;

      const updatedUser = await fetchWrapper.patch(
        `${BASE_URL}/accounts/${userId}`,
        {
          fname,
          lname,
          companyName,
        }
      );

      localStorage.setItem("user", JSON.stringify(updatedUser));
    },
    async uploadProfilePicture(file: File, pageId: string) {
      const formData = new FormData();
      formData.append("image", file);

      return cdnWrapper.post(
        `${BASE_URL}/pageimage/upload/${pageId}`,
        formData
      );
    },
    async createEmptyPage(accountId: string) {
      await fetchWrapper.post(`${BASE_URL}/page/${accountId}`);
    },
    async createPreFilledPage(accountId: string): Promise<CreatePageResponse> {
      const accountStore = useAccountStore();
      return await fetchWrapper.post(`${BASE_URL}/page/${accountId}`, {
        fname: accountStore.user?.fname,
        lname: accountStore.user?.lname,
        company: accountStore.user?.companyName,
      });
    },
    async updatePage(pageId: string, data: UpdatePageData) {
      return await fetchWrapper.patch(`${BASE_URL}/page/${pageId}`, data);
    },
    async createPageItem(
      pageId: string,
      data: UpdatePageItemData
    ): Promise<CreatePageResponse> {
      return await fetchWrapper.post(
        `${BASE_URL}/page/pageItems/${pageId}`,
        data
      );
    },
    async updatePageItem(
      pageId: string,
      itemId: string,
      data: UpdatePageItemData
    ) {
      await fetchWrapper.patch(
        `${BASE_URL}/page/pageItems/${pageId}/${itemId}`,
        data
      );
    },
    async removePageItem(pageId: string, itemId: string) {
      await fetchWrapper.delete(
        `${BASE_URL}/page/pageItems/${pageId}/${itemId}`
      );

      try {
        // Get the index of the item to be removed
        const itemIndex = this.pageItems.findIndex(
          (item) => item.itemId === itemId
        );

        // If the item was found in the list
        if (itemIndex !== -1) {
          // Remove the item from the pageItems array
          this.pageItems.splice(itemIndex, 1);

          // Update the positions of the remaining items
          this.pageItems.forEach((item, index) => {
            // If the item comes after the removed item, decrease its position by 1
            if (index >= itemIndex) {
              item.itemPosition = item.itemPosition - 1;
            }
          });

          // Update all the items in the backend using the pinia store
          this.pageItems.map(async (item) => {
            await this.updatePageItem(pageId, item.itemId, {
              itemPosition: item.itemPosition,
            });
          });
        }
      } catch (error) {
        console.error("Error removing page item:", error);
      }
    },
    async getPageItems(pageId: string) {
      return await fetchWrapper.get(`${BASE_URL}/page/pageItems/${pageId}`);
    },
    setPageItems(pageItems: PageItem[]) {
      this.pageItems = pageItems;
    },
    savePage(pageId: string, isBusiness = false) {
      const link = document.createElement("a");

      const businessUrl = `${BASE_URL}/page/save/${pageId}`;
      const normalUrl = `${BASE_URL}/page/${pageId}/save`;
      link.href = isBusiness ? businessUrl : normalUrl;
      link.download = "contact.vcf";
      link.click();
    },
  },
});
