export const isAppleDevice = /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);

export const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export function getPageId(route) {
  const rawPageId = route.params.id.toString();

  if (Array.isArray(rawPageId)) {
    console.error("Unexpected array for pageId:", rawPageId);
    return null;
  }

  return String(rawPageId);
}
