<template>
  <li>
    <router-link v-if="isInternalLink" :to="link">
      <article :class="{ highlighted: isHighlighted }">
        <div class="left">
          <div v-if="isAvatar" class="avatar">
            <img v-if="imageUrl" :src="imageUrl" width="35" height="35" />
            <IconUser v-else />
          </div>

          <div v-else class="image">
            <img
              class="main-image"
              v-if="imageUrl"
              :src="imageUrl"
              width="35"
              height="35"
            />
            <i v-else-if="icon" :class="icon"></i>
            <IconCard v-else />
          </div>

          <img
            class="sub-image"
            v-if="imageUrl && subImageUrl"
            :src="subImageUrl"
            width="15"
            height="15"
          />

          <div class="info">
            <p class="title">{{ title }}</p>
            <p class="subtitle">{{ subtitle }}</p>
          </div>
        </div>
        <div class="right">
          <div class="action-group-container">
            <div v-if="isActionComponent" class="action-group">
              <slot></slot>
            </div>

            <div v-else class="action-group">
              <button tabindex="-1" v-if="!isHighlighted">
                <IconArrowRight class="small" />
              </button>
            </div>
          </div>
        </div>
      </article>
    </router-link>

    <a v-else :href="link" target="_blank" rel="noopener noreferrer">
      <article :class="{ highlighted: isHighlighted }">
        <div class="left">
          <div v-if="isAvatar" class="avatar">
            <img v-if="imageUrl" :src="imageUrl" width="35" height="35" />
            <IconUser v-else />
          </div>

          <div v-else class="image">
            <img
              class="main-image"
              v-if="imageUrl"
              :src="imageUrl"
              width="35"
              height="35"
            />
            <i v-else-if="icon" :class="icon"></i>
            <IconCard v-else />
          </div>

          <img
            class="sub-image"
            v-if="imageUrl && subImageUrl"
            :src="subImageUrl"
            width="20"
            height="20"
          />

          <div class="info">
            <p class="title">{{ title }}</p>
            <p class="subtitle">{{ subtitle }}</p>
          </div>
        </div>
        <div class="right">
          <div class="action-group-container">
            <div v-if="isActionComponent" class="action-group">
              <slot></slot>
            </div>

            <div v-else class="action-group">
              <button v-if="!isEditing && showIcon" tabindex="-1">
                <IconArrowRight class="small" />
              </button>
              <button v-if="isEditing" @click.prevent="openModal">
                <IconEdit />
              </button>
              <button
                v-if="isEditing"
                :class="{ editing: isEditing }"
                @click.prevent
              >
                <IconArrange />
              </button>
            </div>
          </div>
        </div>
      </article>
    </a>
  </li>
</template>

<script lang="ts">
import IconArrange from "@/components/icons/IconArrange.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconCard from "@/components/icons/IconCard.vue";

export default {
  data() {
    return {
      displayModal: false,
      nameValue: "",
      companyValue: "",
      urlValue: "",
      avatarValue: "",
    };
  },
  components: {
    IconArrange,
    IconCard,
    IconEdit,
    IconArrowRight,
    IconUser,
  },
  props: {
    isAvatar: {
      type: Boolean,
      default: true,
    },
    icon: String,
    imageUrl: String,
    subImageUrl: String,
    title: String,
    subtitle: String,
    link: String,
    isEditing: {
      type: Boolean,
      default: false,
    },
    isActionComponent: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: true,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isInternalLink() {
      return this.link && this.link.startsWith("/");
    },
  },
  methods: {
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/list/item.css";

i {
  font-size: 20px;
  color: var(--color-black);
}

.small {
  max-width: 20px;
}

.left,
.right {
  display: flex;
  align-items: center;
  gap: 12px;
  align-content: center;
}

article {
  padding: 15px 20px 15px 15px;
  gap: 10px;
  border-radius: 6px;
  transition: 0.2s;
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: var(--color-hover);
  }
  .avatar,
  .image {
    height: 38px;
    width: 38px;

    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.08));
  }

  .image img {
    width: 100%;
    height: 100%;
  }

  .avatar {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(210, 9%, 31%); // Create custom property
    border-radius: 50%;
    background-color: var(--color-temp-grey);
    border: 1px solid #e6e6e6;

    img {
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .title {
    color: var(--color-black);
    font-size: 18px;
    line-height: initial;
  }

  .info .subtitle {
    color: var(--color-text-grey);
    font-size: 14px;
    font-weight: 600;
    line-height: initial;
  }
}

.action-group-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.action-group {
  button {
    display: flex;
  }
}

form {
  gap: 2rem;
  margin-top: 1.5rem;
}

article {
  justify-content: space-between;
}

.highlighted {
  background-color: #222;
  color: var(--color-white);

  .title,
  i {
    color: var(--color-white);
  }

  p.subtitle {
    color: #ffffffc2;
  }

  &:hover {
    background-color: #2d2d2d;
  }
}

.sub-image {
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 4px;
}
</style>
