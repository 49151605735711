import { defineStore } from "pinia";
import { BASE_URL } from "@/helpers";

export const useCardTypeStore = defineStore({
  id: "cardType",
  state: () => ({
    cardTypes: [], // Store card types in the state
  }),
  actions: {
    async addCardType(cardData) {
      // Create a new FormData object
      const formData = new FormData();
      formData.append('cardCategoryName', cardData.cardCategoryName);
      formData.append('cardTypeId', cardData.cardTypeId);
      formData.append('description', cardData.description);
      formData.append('file', cardData.file);

      try {
        const response = await fetch(
          `${BASE_URL}/cardType/addCardType`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          return await response.json();
        } else {
          const errorData = await response.json();
          throw new Error(`Error adding card type: ${JSON.stringify(errorData)}`);
        }
      } catch (error) {
        console.error("Error adding card type:", error);
        throw error;
      }
    },

    async getAllCardTypes() {
      try {
        const response = await fetch(`${BASE_URL}/cardType/getCardTypes`, {
          method: "GET",
        });

        if (response.ok) {
          const text = await response.text();
          if (text) {
            const data = JSON.parse(text);
            this.cardTypes = data; // Update the state with fetched card types
            return data;
          } else {
            throw new Error("Received empty response");
          }
        } else {
          const errorData = await response.json();
          throw new Error(`Error fetching card types: ${JSON.stringify(errorData)}`);
        }
      } catch (error) {
        console.error("Error fetching card types:", error);
        throw error;
      }
    },
  },
});




// import { defineStore } from "pinia";
// import { BASE_URL } from "@/helpers";

// export const useCardTypeStore = defineStore({
//   id: "cardType",
//   actions: {
//     async addCardType(cardData) {

//       // Create a new FormData object
//       const formData = new FormData();
//       formData.append('cardCategoryName', cardData.cardCategoryName);
//       formData.append('cardTypeId', cardData.cardTypeId);
//       formData.append('description', cardData.description);
//       formData.append('file', cardData.file);

//       try {
//         const response = await fetch(
//           `${BASE_URL}/cardType/addCardType`,
//           {
//             method: "POST",
//             body: formData,
//           }
//         );

//         if (response.ok) {
//           return await response.json();
//         } else {
//           const errorData = await response.json();
//           throw new Error(`Error adding card type: ${JSON.stringify(errorData)}`);
//         }
//       } catch (error) {
//         console.error("Error adding card type:", error);
//         throw error;
//       }
//     },
//   },
// });