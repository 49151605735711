<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_321_224)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.17734 10.4824C9.01285 11.0406 9.99514 11.3386 11 11.3386C12.3475 11.3386 13.6398 10.8033 14.5926 9.85051C15.5454 8.89771 16.0806 7.60543 16.0806 6.25795C16.0806 5.2531 15.7827 4.27081 15.2244 3.4353C14.6661 2.59979 13.8726 1.94859 12.9443 1.56405C12.0159 1.17951 10.9944 1.07889 10.0088 1.27493C9.02326 1.47097 8.11798 1.95485 7.40744 2.6654C6.6969 3.37594 6.21301 4.28122 6.01697 5.26677C5.82094 6.25232 5.92155 7.27387 6.30609 8.20223C6.69064 9.1306 7.34183 9.92409 8.17734 10.4824ZM9.3064 3.72331C9.80771 3.38835 10.3971 3.20957 11 3.20957C11.8085 3.20957 12.5838 3.53074 13.1555 4.10242C13.7272 4.6741 14.0484 5.44947 14.0484 6.25795C14.0484 6.86087 13.8696 7.45024 13.5346 7.95155C13.1997 8.45285 12.7236 8.84357 12.1666 9.0743C11.6095 9.30502 10.9966 9.36539 10.4053 9.24777C9.81396 9.13015 9.27079 8.83981 8.84446 8.41349C8.41814 7.98716 8.12781 7.44399 8.01018 6.85267C7.89256 6.26134 7.95293 5.64841 8.18366 5.09139C8.41438 4.53437 8.8051 4.05827 9.3064 3.72331ZM19.7692 20.5211C19.959 20.711 20.2155 20.8191 20.4839 20.8226C20.7523 20.8191 21.0087 20.711 21.1985 20.5211C21.3883 20.3313 21.4965 20.0749 21.5 19.8065C21.5 13.371 14.1432 13.371 11 13.371C7.85677 13.371 0.5 13.371 0.5 19.8065C0.5 20.076 0.607056 20.3345 0.797617 20.525C0.988178 20.7156 1.24663 20.8226 1.51613 20.8226C1.78562 20.8226 2.04408 20.7156 2.23464 20.525C2.4252 20.3345 2.53226 20.076 2.53226 19.8065C2.53226 17.1646 3.96839 15.4033 11 15.4033C18.0316 15.4033 19.4677 17.1646 19.4677 19.8065C19.4713 20.0749 19.5794 20.3313 19.7692 20.5211Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_321_224">
        <rect
          width="21"
          height="21"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
