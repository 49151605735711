<template>
  <svg
    width="171"
    height="171"
    viewBox="0 0 171 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.5 16.0312C103.924 16.0313 121.594 23.3503 134.622 36.3782C147.65 49.4061 154.969 67.0757 154.969 85.5C154.969 103.924 147.65 121.594 134.622 134.622C121.594 147.65 103.924 154.969 85.5 154.969C67.0757 154.969 49.4061 147.65 36.3782 134.622C23.3503 121.594 16.0313 103.924 16.0312 85.5C16.0313 67.0757 23.3503 49.4061 36.3782 36.3782C49.4061 23.3503 67.0757 16.0313 85.5 16.0312ZM85.5 171C108.176 171 129.923 161.992 145.958 145.958C161.992 129.923 171 108.176 171 85.5C171 62.824 161.992 41.0767 145.958 25.0424C129.923 9.00801 108.176 0 85.5 0C62.824 0 41.0767 9.00801 25.0424 25.0424C9.00801 41.0767 0 62.824 0 85.5C0 108.176 9.00801 129.923 25.0424 145.958C41.0767 161.992 62.824 171 85.5 171ZM58.4473 58.4473C55.3078 61.5867 55.3078 66.6633 58.4473 69.7693L74.1445 85.4666L58.4473 101.164C55.3078 104.303 55.3078 109.38 58.4473 112.486C61.5867 115.592 66.6633 115.625 69.7693 112.486L85.4666 96.7887L101.164 112.486C104.303 115.625 109.38 115.625 112.486 112.486C115.592 109.346 115.625 104.27 112.486 101.164L96.7887 85.4666L112.486 69.7693C115.625 66.6299 115.625 61.5533 112.486 58.4473C109.346 55.3412 104.27 55.3078 101.164 58.4473L85.4666 74.1445L69.7693 58.4473C66.6299 55.3078 61.5533 55.3078 58.4473 58.4473Z"
      fill="#FF3D32"
    />
  </svg>
</template>
