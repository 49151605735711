export function generatePlatformData(itemType: string, itemData: string) {
  const platformData = {
    linkUrl: "",
  };

  switch (itemType) {
    case "url":
      platformData.linkUrl = `http://${itemData}`;
      break;

    case "email":
      platformData.linkUrl = `mailto:${itemData}`;
      break;

    case "phone":
      platformData.linkUrl = `tel:${itemData}`;
      break;

    case "address": {
      const addressFieldsArray = itemData.split(";");
      const formattedAddressString = addressFieldsArray
        .join("+")
        .replace(/\s/g, "+");

      platformData.linkUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddressString}`;

      break;
    }

    case "message":
      platformData.linkUrl = `sms:${itemData}`;
      break;

    case "facetime":
      platformData.linkUrl = `facetime:${itemData}`;
      break;

    case "linkedin":
      platformData.linkUrl = `https://linkedin.com/in/${itemData}`;
      break;

    case "linkedin-company":
      platformData.linkUrl = `https://linkedin.com/company/${itemData}`;
      break;

    case "whatsapp":
      platformData.linkUrl = `https://wa.me/${itemData}`;
      break;

    case "telegram":
      platformData.linkUrl = `https://t.me/${itemData}`;
      break;

    case "instagram":
      platformData.linkUrl = `https://instagram.com/${itemData}`;
      break;

    case "discord-server":
      platformData.linkUrl = `https://discord.gg/${itemData}`;
      break;

    case "snapchat":
      platformData.linkUrl = `https://snapchat.com/add/${itemData}`;
      break;

    case "twitch":
      platformData.linkUrl = `https://twitch.tv/${itemData}`;
      break;

    case "pinterest":
      platformData.linkUrl = `https://pinterest.com/${itemData}`;
      break;

    case "youtube":
      platformData.linkUrl = `https://youtube.com/@${itemData}`;
      break;

    case "twitter":
      platformData.linkUrl = `https://twitter.com/${itemData}`;
      break;

    case "facebook":
      platformData.linkUrl = `https://facebook.com/${itemData}`;
      break;

    case "tiktok":
      platformData.linkUrl = `https://tiktok.com/@${itemData}`;
      break;

    case "spotify":
      platformData.linkUrl = `https://open.spotify.com/user/${itemData}`;
      break;

    case "applemusic":
      platformData.linkUrl = `https://music.apple.com/profile/${itemData}`;
      break;

    case "soundcloud":
      platformData.linkUrl = `https://soundcloud.com/${itemData}`;
      break;

    case "tikkie":
      platformData.linkUrl = `https://${itemData}`;
      break;

    case "paypal":
      platformData.linkUrl = `https://paypal.me/${itemData}`;
      break;

    case "calendly":
      platformData.linkUrl = `https://calendly.com/${itemData}`;
      break;

    default:
      console.log(`Unsupported item type: ${itemType}`);
  }

  return platformData;
}

export function processUserInput(input: string, itemType: string) {
  // Process URLs for specific platforms
  const platformRegexMap = [
    {
      platform: "linkedin",
      regex: /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([\w-]+)/i,
    },
    {
      platform: "linkedin-company",
      regex: /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/company\/([\w-]+)/i,
    },
    {
      platform: "facebook",
      regex: /(?:https?:\/\/)?(?:www\.)?facebook\.com\/([\w-]+)/i,
    },
    {
      platform: "soundcloud",
      regex: /(?:https?:\/\/)?(?:www\.)?soundcloud\.com\/([\w-]+)/i,
    },
    {
      platform: "calendly",
      regex: /(?:https?:\/\/)?(?:www\.)?calendly\.com\/(.+)/i,
    },
    {
      platform: "twitch",
      regex: /(?:https?:\/\/)?(?:www\.)?twitch\.tv\/([\w-]+)/i,
    },
    {
      platform: "paypal",
      regex: /(?:https?:\/\/)?(?:www\.)?paypal\.me\/([\w-]+)/i,
    },
    {
      platform: "spotify",
      regex: /(?:https?:\/\/)?(?:www\.)?open\.spotify\.com\/user\/([\w-]+)/i,
    },

    // Add more platforms as needed
  ];

  const handlePlatforms = [
    "instagram",
    "twitter",
    "tiktok",
    "snapchat",
    "pinterest",
    "youtube",
    "telegram",
    "twitch",
  ];

  const phoneNumberPlatform = ["whatsapp", "phone", "facetime", "message"];

  if (handlePlatforms.includes(itemType)) {
    if (input.includes("/")) {
      return input.split("/")[1];
    }
    if (input.includes("@")) {
      return input.split("@")[1];
    }
  }

  if (phoneNumberPlatform.includes(itemType)) {
    const phoneRegex = /\+?[0-9\s-]{7,15}/i;
    const match = input.match(phoneRegex);
    if (match) {
      return match[0].replace(/\s+/g, "");
    } else {
      return input;
    }
  }

  if (itemType === "url") {
    const generalUrlRegex = /(?:https?:\/\/)?(?:www\.)?(.+)/i;
    const generalMatch = input.match(generalUrlRegex);
    if (generalMatch) {
      return generalMatch[1];
    }
  }

  for (const { platform, regex } of platformRegexMap) {
    const match = input.match(regex);
    if (match) {
      return match[1] ?? match["input"];
    }
  }

  return input; // If no match, return the input as is
}

export function getInputType(itemType: string) {
  switch (itemType) {
    case "phone":
      return "tel";

    case "whatsapp":
      return "tel";

    case "email":
      return "email";

    default:
      return "text";
  }
}

export function getInputPattern(itemType: string) {
  switch (itemType) {
    case "email":
      return "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*$";

    case "phone":
      return "^\\+ ?([0-9 ]+)+$";

    case "address":
      return "^[^;]+;[^;]+;[^;]+;[^;]+;[^;]+$";

    case "message":
      return "^\\+ ?([0-9 ]+)+$";

    case "facetime":
      return "^\\+ ?([0-9 ]+)+$";

    case "linkedin":
      return "^[^\\s]*$";

    case "linkedin-company":
      return "^[^\\s]*$";

    // TODO: Safari doesn't understands this regex, need to fix later
    // case "linkedin":
    //   return /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w-]+|^[\w-]+$/i;

    // case "linkedin-company":
    //   return /^(https?:\/\/)?(www\.)?linkedin\.com\/company\/[\w-]+|^[\w-]+$/i;

    case "whatsapp":
      return "^\\+ ?([0-9 ]+)+$";

    case "telegram":
      return "^(t.me/|https://t.me/)?@?[a-zA-Z0-9_]{5,32}$";

    case "instagram":
      return "^(?:@)?[a-zA-Z0-9._]{1,30}$";

    case "snapchat":
      return "^(?:@)?[a-zA-Z][a-zA-Z0-9-_.]{1,13}[a-zA-Z0-9]$";

    case "discord-server":
      return "^[^\\s]*$";

    case "twitch":
      return "^(twitch.tv/|https://twitch.tv/)?[a-zA-Z0-9_]+$";

    case "pinterest":
      return "^@?[a-zA-Z][a-zA-Z0-9_]{2,29}$";

    case "youtube":
      return "^@[a-zA-Z0-9_.-]{3,30}$";

    case "twitter":
      return "^(?:@?[a-zA-Z0-9_]{4,15}|(?:https?://)?(?:x.com|twitter.com)/[a-zA-Z0-9_]{4,15})$";

    case "facebook":
      return "^[^\\s]*$";

    case "tiktok":
      return "^(?:@?[a-zA-Z0-9._]{1,24})$";

    case "spotify":
      return "^[^\\s]*$";

    case "applemusic":
      return "^[^\\s]*$";

    case "soundcloud":
      return "^[^\\s]*$";

    case "tikkie":
      return "^[^\\s]+$";

    case "paypal":
      return "^[^\\s]*$";

    case "calendly":
      return "^[^\\s]*$";

    default:
      return ".*";
  }
}

export function generatePlaceholder(itemType: string) {
  switch (itemType) {
    case "url":
      return "example.com";

    case "email":
      return "example@connectflow.io";

    case "phone":
      return "+31612345678";

    case "address":
      return "Street;Number;Zipcode;City;State;Country";

    case "message":
      return "+31612345678";

    case "facetime":
      return "Phone or email";

    case "linkedin":
      return "linkedin.com/in/...";

    case "linkedin-company":
      return "linkedin.com/company/...";

    case "whatsapp":
      return "+31612345678";

    case "telegram":
      return "t.me/...";

    case "instagram":
      return "username";

    case "discord-server":
      return "discord.gg/...";

    case "snapchat":
      return "username";

    case "twitch":
      return "username";

    case "pinterest":
      return "username";

    case "youtube":
      return "@username";

    case "twitter":
      return "elonmusk";

    case "facebook":
      return "facebook.com/...";

    case "tiktok":
      return "username";

    case "spotify":
      return "open.spotify.com/user/...";

    case "applemusic":
      return "music.apple.com/us/album/...";

    case "soundcloud":
      return "soundcloud.com/...";

    case "tikkie":
      return "tikkie.me/...";

    case "paypal":
      return "paypal.me/...";

    case "calendly":
      return "calendly.com/...";

    default:
      return "Enter an url";
  }
}

export function generateInputTitle(itemType: string) {
  switch (itemType) {
    case "url":
      return "Enter an URL";

    case "email":
      return "Enter your email";

    case "phone":
      return "Include your country code :)";

    case "address":
      return "Enter the address like this: Street, Number, Zipcode, City, State, Country";

    case "message":
      return "Include your country code :)";

    case "facetime":
      return "Your email or phone linked to your iCloud. Including your country code";

    case "linkedin":
      return "Enter your LinkedIn username";

    case "linkedin-company":
      return "Enter your LinkedIn company url";

    case "whatsapp":
      return "Include your country code :)";

    case "telegram":
      return "Enter your Telegram username";

    case "instagram":
      return "Enter your Instagram username";

    case "discord-server":
      return "Enter your Discord server URL";

    case "snapchat":
      return "Enter your Snapchat username";

    case "twitch":
      return "Enter your Twitch username";

    case "pinterest":
      return "Enter your Pinterest username";

    case "youtube":
      return "Enter your YouTube username";

    case "twitter":
      return "Enter your Twitter username";

    case "facebook":
      return "Enter your Facebook profile link";

    case "tiktok":
      return "Enter your TikTok username";

    case "spotify":
      return "Enter your Spotify username";

    case "applemusic":
      return "Enter a Apple Music URL";

    case "soundcloud":
      return "Enter a SoundCloud URL";

    case "tikkie":
      return "Enter your Tikkie URL";

    case "paypal":
      return "Enter your PayPal URL";

    case "calendly":
      return "Enter your Calendly URL";

    default:
      return "Enter an URL";
  }
}
export function generateLabelText(itemType: string) {
  switch (itemType) {
    case "url":
      return "url";

    case "email":
      return "email";

    case "phone":
      return "phone";

    case "address":
      return "adress";

    case "message":
      return "phone";

    case "facetime":
      return "phone or email";

    case "linkedin":
      return "username";

    case "linkedin-company":
      return "company";

    case "whatsapp":
      return "phone";

    case "telegram":
      return "username";

    case "instagram":
      return "username";

    case "discord-server":
      return "url";

    case "snapchat":
      return "username";

    case "twitch":
      return "username";

    case "pinterest":
      return "username";

    case "youtube":
      return "username";

    case "twitter":
      return "username";

    case "facebook":
      return "profile url";

    case "tiktok":
      return "username";

    case "spotify":
    case "applemusic":
    case "soundcloud":
      return "url";

    case "tikkie":
      return "url";

    case "paypal":
      return "url";

    case "calendly":
      return "url";

    default:
      return "Enter your url";
  }
}

export const dataKeys = {
  WhatsApp: "phone number",
  Snapchat: "username",
  Instagram: "username",
  YouTube: "username",
  Spotify: "username",
  Address: "address",
  Email: "email",
  Phone: "phone number",
  Message: "phone number",
  Facetime: "phone or email",
  LinkedIn: "username",
  LinkedInCompany: "company",
  Telegram: "username",
  "Discord Server": "url",
  Twitch: "username",
  Pinterest: "username",
  Twitter: "username",
  Facebook: "profile",
  TikTok: "username",
  "Apple Music": "url",
  SoundCloud: "url",
  Tikkie: "url",
  PayPal: "url",
  Calendly: "url",
  URL: "link",
  Website: "link",
};
