<template>
  <svg
    width="36"
    height="28"
    viewBox="0 0 18 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 1.60001H14.6C14.9707 1.60211 15.3255 1.75028 15.5876 2.01238C15.8497 2.27447 15.9979 2.62935 16 3.00001V11C15.9979 11.3707 15.8497 11.7255 15.5876 11.9876C15.3255 12.2497 14.9707 12.3979 14.6 12.4H3.4C3.02934 12.3979 2.67447 12.2497 2.41237 11.9876C2.15027 11.7255 2.0021 11.3707 2 11V3.00001C2.0021 2.62935 2.15027 2.27447 2.41237 2.01238C2.67447 1.75028 3.02934 1.60211 3.4 1.60001ZM14.6 2.80001H3.4C3.34696 2.80001 3.29609 2.82108 3.25858 2.85858C3.22107 2.89609 3.2 2.94696 3.2 3.00001V4.80001H14.8V3.00001C14.8 2.94696 14.7789 2.89609 14.7414 2.85858C14.7039 2.82108 14.653 2.80001 14.6 2.80001ZM3.4 11.2H14.6C14.653 11.2 14.7039 11.1789 14.7414 11.1414C14.7789 11.1039 14.8 11.053 14.8 11V6.00001H3.2V11C3.2 11.053 3.22107 11.1039 3.25858 11.1414C3.29609 11.1789 3.34696 11.2 3.4 11.2ZM5.00001 7.80002H6.60001C6.81219 7.80002 7.01567 7.8843 7.1657 8.03433C7.31573 8.18436 7.40001 8.38785 7.40001 8.60002C7.40001 8.81219 7.31573 9.01567 7.1657 9.1657C7.01567 9.31573 6.81219 9.40002 6.60001 9.40002H5.00001C4.78784 9.40002 4.58436 9.31573 4.43433 9.1657C4.2843 9.01567 4.20001 8.81219 4.20001 8.60002C4.20001 8.38785 4.2843 8.18436 4.43433 8.03433C4.58436 7.8843 4.78784 7.80002 5.00001 7.80002Z"
    />
  </svg>
</template>
