import { defineStore } from "pinia";
import { fetchWrapper, BASE_URL } from "@/helpers";

import type { ConnectionState, Connection } from "./types";

export const useConnectionStore = defineStore({
  id: "connections",
  state: (): ConnectionState => ({
    connections: [] as Connection[],
  }),
  actions: {
    async getConnections(accountId: string) {
      const connections = await fetchWrapper.get(
        `${BASE_URL}/connect/list/${accountId}`
      );

      this.connections = connections;

      return connections;
    },
    async getConnection(connectId: string) {
      return await fetchWrapper.get(`${BASE_URL}/connect/${connectId}`);
    },
    async createConnection(accountId: string, data) {
      return await fetchWrapper.post(`${BASE_URL}/connect/${accountId}`, data);
    },
    async updateConnection(connectId: string, data) {
      return await fetchWrapper.patch(`${BASE_URL}/connect/${connectId}`,data);
    },
    async deleteConnection(connectId: string) {
      return await fetchWrapper.delete(`${BASE_URL}/connect/${connectId}`);
    },
  },
});
