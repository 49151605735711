<script lang="ts">
import { useBusinessConnectionStore, useAccountStore } from "@/stores";
import { formatDate } from "@/helpers";
import { defineAsyncComponent } from "vue";

export default {
  data() {
    return {
      searchQuery: "",
      businessConnectionsStore: useBusinessConnectionStore(),
      accountStore: useAccountStore(),
      businessId: "",
      connectionsCount: 0,
      connectionsArray: [],
      areConnectionLoaded: false,
    };
  },
  components: {
    PVDataTable: defineAsyncComponent(() => import("primevue/datatable")),
  },
  async created() {
    this.businessId = this.accountStore.user.belongToBusinessObjId;
    await this.businessConnectionsStore
      .getBusinessConnections(this.businessId)
      .then((data) => {
        this.connectionsArray = data.rows;
        this.areConnectionLoaded = true;
      });
  },
  computed: {
    filteredConnections() {
      const searchQueryLower = this.searchQuery.toLowerCase();
      const userId = this?.accountStore?.user?.userID;

      if (!this.businessConnectionsStore.connections) {
        return [];
      }

      return this.businessConnectionsStore.connections
        .filter((connection) => {
          const fullName =
            `${connection?.fname} ${connection?.lname}`.toLowerCase();
          const companyName = connection?.company?.toLowerCase();

          // Check if the user is an admin or the connection is related to the account
          const isAdmin = this.accountStore.isUserAdmin;
          const isRelatedToAccount =
            connection?.connectedWith?.userId === userId ?? false;

          // Apply filtering logic based on isAdmin and connection related to the account
          if (isAdmin || isRelatedToAccount) {
            return (
              fullName.includes(searchQueryLower) ||
              companyName?.includes(searchQueryLower)
            );
          }

          return false; // Exclude connections that don't match the criteria
        })
        .map((connection) => {
          return {
            ...connection,
            fullName: `${connection?.fname} ${connection?.lname}`,
            company: connection?.company,
            connectedWith: `${connection?.connectedWith?.fname ?? ""} ${
              connection?.connectedWith?.lname ?? ""
            }`,
            dateConnected: formatDate(connection.createdAt),
          };
        })
        .sort((a, b) => {
          return new Date(b.dateConnected) - new Date(a.dateConnected);
        });
    },
  },
  methods: {
    onRowSelect(event) {
      const connectionId = event.data.connectId;
      this.$router.push(`/business/connections/connection/${connectionId}`);
    },
  },
};
</script>

<template>
  <div class="connections-container">
    <span class="p-input-icon-left input-icon full-width">
      <i class="pi pi-search" />
      <PVInputText v-model="this.searchQuery" placeholder="Find connections" />
    </span>
    <div v-if="this.filteredConnections.length < 1">
      <PVSkeleton v-if="!areConnectionLoaded" width="100%" height="240px" />
      <div class="no-connections-wrap" v-else>
        <p class="no-connections">No connections yet</p>
      </div>
    </div>

    <div v-else class="connections-container">
      <div class="data-table-container">
        <PVDataTable
          unstyled
          :value="filteredConnections"
          tableStyle="min-width: 100%"
          @rowSelect="onRowSelect"
          selectionMode="single"
          dataKey="id"
          class="data-table"
        >
          <PVColumn
            field="fullName"
            header="Full Name"
            sortable
            style="width: 100%"
            class="column-fullname column"
          ></PVColumn>
          <PVColumn
            field="company"
            header="Company"
            sortable
            style="width: 100%"
            class="column"
          ></PVColumn>
          <PVColumn
            field="connectedWith"
            header="With"
            sortable
            style="width: 100%"
            class="column"
          ></PVColumn>
          <PVColumn
            field="dateConnected"
            sortField="createdAt"
            header="Date"
            sortable
            style="width: 100%"
            class="column"
          ></PVColumn>
        </PVDataTable>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/datatable.css";
.p-input-icon-left {
  position: relative;
  display: flex;
  align-items: center;
}

.p-input-icon-left .pi {
  position: absolute;
  left: 10px;
  font-size: 1rem;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.p-input-icon-left input {
  padding-left: 40px;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.data-table-container {
  background-color: white;
  margin-top: 30px;
  padding: 5px;
  border-radius: 10px;
  max-width: 100%;
  animation: move-up-100 0.8s cubic-bezier(0, 0, 0, 1) forwards;
  transition: all 0.2s;
}

.data-table-container:hover {
  transition: all 0.2s;
  box-shadow: 0 5px 40px -10px #00000014;
}

.no-connections-wrap {
  display: flex;
  padding-top: 30px;
  justify-content: center;
}

.p-sortable-column.column {
  display: flex;
}

.no-connections {
  font-weight: 600;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}
</style>
