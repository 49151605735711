<template>
  <li>
    <a v-if="link" :href="link" :target="newTabLink ? '_blank' : '_self'">
      <article>
        <div v-if="isAvatar" class="avatar">
          <img v-if="imageUrl" :src="imageUrl" />
          <IconUser v-else />
        </div>

        <div v-else class="image" :class="{ bg: hasIconBackground }">
          <img v-if="imageUrl" :src="imageUrl" />
          <i v-else-if="icon" :class="icon"></i>
          <IconCard v-else />
        </div>

        <div class="info">
          <p class="title">{{ title }}</p>
          <p class="subtitle">{{ subtitle }}</p>
        </div>

        <div class="action-group-container">
          <button v-if="!hasIcon && showIcon">
            <IconArrowRight />
          </button>
          <slot></slot>
        </div>
      </article>
    </a>

    <article v-else>
      <div v-if="isAvatar" class="avatar">
        <img v-if="imageUrl" :src="imageUrl" />
        <IconUser v-else />
      </div>

      <div v-else class="image" :class="{ bg: hasIconBackground }">
        <img v-if="imageUrl" :src="imageUrl" />
        <i v-else-if="icon" :class="icon"></i>
        <IconCard v-else />
      </div>

      <div class="info">
        <p class="title">{{ title }}</p>
        <p class="subtitle">{{ subtitle }}</p>
      </div>

      <div class="action-group-container">
        <button v-if="!hasIcon && showIcon" tabindex="-1">
          <IconArrowRight />
        </button>
        <slot></slot>
      </div>
    </article>
  </li>
</template>

<script lang="ts">
import IconUser from "@/components/icons/IconUser.vue";
import IconCard from "@/components/icons/IconCard.vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";

export default {
  components: {
    IconCard,
    IconUser,
    IconArrowRight,
  },
  props: {
    link: String,
    isAvatar: {
      type: Boolean,
      default: true,
    },
    icon: String,
    imageUrl: String,
    title: String,
    subtitle: String,
    hasIcon: {
      type: Boolean,
      default: true,
    },
    hasIconBackground: {
      type: Boolean,
      default: false,
    },
    newTabLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/list/item.css";
i {
  font-size: 20px;
  color: var(--color-black);
}

.bg {
  background-color: var(--color-grey);
  border-radius: 50%;
}

article {
  padding: 15px 20px 15px 15px;
  border-radius: 6px;
  transition: 0.2s;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: var(--color-hover);
    outline: none;
  }

  .avatar,
  .image {
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image img {
    width: 100%;
    height: 100%;
  }

  .avatar {
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(210, 9%, 31%); // Create custom property
    border-radius: 50%;
    background-color: var(--color-temp-grey);
    border: 1px solid #e6e6e6;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .title {
    font-size: 18px;
    line-height: initial;
    font-weight: 600;

    @media screen and (max-width: 467px) {
      max-width: 11em;
    }
  }

  //   .info {
  //     display: flex;
  //     flex-direction: column;
  //     row-gap: 2px;

  //     @media screen and (max-width: 487px) {
  //       max-width: 210px;
  //       white-space: nowrap;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //     }
  //   }

  .info .subtitle {
    color: var(--color-text-grey);
    font-size: 14px;
    font-weight: 500;
    line-height: initial;
  }
}
.action-group-container {
  position: absolute;
  right: 4%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

form {
  gap: 2rem;
  margin-top: 1.5rem;
}
</style>
