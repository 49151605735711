<template>
  <div class="wrapper">
    <div class="wrapper-top">
      <div class="color-picker-container">
        <span>Card background color</span>
        <div class="color-picker-button">
          <p>Background</p>
          <div
            class="color-preview"
            :style="{
              backgroundColor:
                this.businessSettingsStore.businessSettings
                  .virtualCardBackgroundColor ?? '#FFFFFF',
            }"
          />
        </div>

        <InputWrapper
          labelText="HEX Code"
          labelRef="bgColor"
          class="color-picker-input"
          placeholder="#FFFFFF"
          variant="grey"
          v-model="
            this.businessSettingsStore.businessSettings
              .virtualCardBackgroundColor
          "
          @change="updateVirtualBgColor"
        />
      </div>
      <div class="wallet-logo-container__google">
        <span>Google Wallet icon</span>
        <PVFileUpload
          id="logo-icon"
          class="btn btn-primary btn-upload"
          mode="basic"
          accept="image/*"
          :maxFileSize="1000000"
          :auto="true"
          chooseLabel="Upload
    image"
          @uploader="uploadVirtualLogoIcon($event, 'icon')"
          customUpload
        />
        <img
          id="logoIcon"
          class="removable"
          v-if="virtualCardLogoIcon"
          width="100"
          :src="virtualCardLogoIcon"
          @click="removeImage('icon')"
        />

        <div v-else class="preview logo-icon-preview" />
      </div>
    </div>

    <div class="wrapper-bottom">
      <div class="wallet-logo-container__apple">
        <span>Apple Wallet logo</span>
        <PVFileUpload
          id="logo-icon"
          class="btn btn-primary btn-upload"
          mode="basic"
          accept="image/*"
          :maxFileSize="1000000"
          :auto="true"
          chooseLabel="Upload
    image"
          @uploader="uploadVirtualLogoIcon($event, 'apple')"
          customUpload
        />
        <img
          id="logoIcon"
          class="removable"
          v-if="virtualCardLogoApple"
          width="100"
          :src="virtualCardLogoApple"
          @click="removeImage('apple')"
        />

        <div v-else class="preview logo-icon-preview" />
      </div>
      <div class="wallet-logo-container__google">
        <span>Google Wallet logo</span>
        <PVFileUpload
          id="logo-icon"
          class="btn btn-primary btn-upload"
          mode="basic"
          accept="image/*"
          :maxFileSize="1000000"
          :auto="true"
          chooseLabel="Upload
    image"
          @uploader="uploadVirtualLogoIcon($event, 'google')"
          customUpload
        />
        <img
          id="logoIcon"
          class="removable"
          v-if="virtualCardLogoGoogle"
          width="100"
          :src="virtualCardLogoGoogle"
          @click="removeImage('google')"
        />

        <div v-else class="preview logo-icon-preview" />
      </div>
    </div>

    <span>Check contrast</span>

    <div class="card-container">
      <div
        class="card"
        :style="{
          backgroundColor:
            this.businessSettingsStore.businessSettings
              .virtualCardBackgroundColor ?? '#FFFFFF',
        }"
      >
        <div class="logo-container logo-container__apple">
          <div class="logo-placeholder" v-if="!virtualCardLogoApple"></div>
          <img v-else :src="virtualCardLogoApple" alt="" class="virtual-logo" />
        </div>
        <div class="logo-container logo-container__google">
          <div class="logo-placeholder" v-if="!virtualCardLogoGoogle"></div>
          <img
            v-else
            :src="virtualCardLogoGoogle"
            alt=""
            class="virtual-logo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InputWrapper from "@/components/Input/InputWrapper.vue";

import { useBusinessSettingsStore } from "@/stores";

export default {
  data() {
    return {
      businessSettingsStore: useBusinessSettingsStore(),
    };
  },
  props: {
    businessId: {
      type: String,
    },
  },
  components: {
    InputWrapper,
  },
  computed: {
    virtualCardLogoApple() {
      return this.businessSettingsStore.businessSettings.virtualCardLogo;
    },
    virtualCardLogoGoogle() {
      return this.businessSettingsStore.businessSettings.virtualCardLogoGoogle;
    },
    virtualCardLogoIcon() {
      return this.businessSettingsStore.businessSettings.virtualCardLogoIcon;
    },
  },
  methods: {
    updateVirtualBgColor() {
      const updatedSetting = {
        virtualCardBackgroundColor:
          this.businessSettingsStore.businessSettings.virtualCardBackgroundColor?.toUpperCase(),
      };
      this.businessSettingsStore
        .setBusinessSettings(this.businessId, updatedSetting)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: `Background color is updated.`,
            life: 5000,
          });
        });
    },
    async uploadVirtualLogoIcon(event, type: string) {
      const logoType =
        type === "google"
          ? "virtualCardLogoGoogle"
          : type === "icon"
          ? "virtualCardLogoIcon"
          : type === "apple"
          ? "virtualCardLogo"
          : undefined;

      const file = await event.files[0];

      //   await this.resetImage(type);
      try {
        await this.businessSettingsStore.uploadBusinessAssetType(
          this.businessId,
          logoType,
          file
        );

        this.businessSettingsStore.getBusinessSettings(this.businessId);
      } catch (error) {
        console.log(error);
      }
    },
    removeImage(type: string) {
      let updatedSetting = {};

      if (type === "google") {
        updatedSetting = {
          virtualCardLogoGoogle: "",
        };
      } else if (type === "apple") {
        updatedSetting = {
          virtualCardLogo: "",
        };
      } else {
        updatedSetting = {
          virtualCardLogoIcon: "",
        };
      }

      this.$confirm.require({
        message: "Are you sure you want to delete the image?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessSettingsStore
            .setBusinessSettings(this.businessId, updatedSetting)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: "Image is removed",
                life: 5000,
              });
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}
span {
  color: var(--color-text-grey);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}

span:not(:nth-of-type(1)) {
  margin-top: 18px;
}

.preview {
  background-color: var(--color-background);
  margin-top: 16px;
  border-radius: 10px;
}

.logo-preview {
  height: 76px;
  width: 191px;
}

.logo-icon-preview {
  height: 76px;
  width: 162px;
}

.color-pickers-container {
  display: flex;
  gap: 16px;
}

.color-picker-button {
  background-color: transparent;
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 12px;
  width: 150px;

  p {
    color: #222;
    font-size: 14px;
    font-weight: 600;
  }
}

img.removable {
  margin-top: 8px;
  border: 1px solid var(--color-grey-border);
}

img.removable:hover {
  opacity: 0.7;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
}

.color-preview {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0, 0.1);
}

.card-container {
  .card {
    border: 1px solid var(--color-grey-border);
    width: 377px;
    height: 143px;
    background-color: var(--color-primary);
    border-radius: 10px;
    .logo-container {
      width: 83px;
      height: 32px;
      position: absolute;
      transform: translate(-50%, -50%);

      &__apple {
        left: 25%;
        top: 50%;
      }

      &__google {
        left: 75%;
        top: 50%;
      }

      .logo-placeholder {
        width: 100%;
        height: 100%;
        background-color: white;
      }

      .virtual-logo {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.wrapper-top,
.wrapper-bottom {
  display: flex;
  gap: 64px;

  > div {
    width: 200px;
  }
}

.wrapper-bottom {
  margin: 32px 0;
}
</style>
