<script lang="ts">
import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListItem from "@/components/List/ListItem/ArticleListItem.vue";

import {
  useAccountStore,
  usePagesStore,
  useCardsStore,
  useAuthStore,
  useBusinessUsersStore,
  useBusinessCardsStore,
  useBusinessSettingsStore,
} from "@/stores";

import { IS_DEVELOPMENT, ACCOUNT_ID } from "@/helpers";

export default {
  data() {
    return {
      cardType: "",
      cardImage: "",
      accountId: ACCOUNT_ID,
      searchQuery: "",
      cardId: "",
      pageId: "",
      isLoading: false,
      isBusinessUser: false,
      businessId: "",
      profileImage: null,
      accountStore: useAccountStore(),
      pagesStore: usePagesStore(),
      cardsStore: useCardsStore(),
      authStore: useAuthStore(),
      businessUsersStore: useBusinessUsersStore(),
      businessCardsStore: useBusinessCardsStore(),
      businessSettingsStore: useBusinessSettingsStore(),
    };
  },
  components: {
    ArticleListContainer,
    ArticleListItem,
  },
  async created() {
    this.accountId = localStorage.getItem("accountId") ?? undefined; // Workaround, need to have this

    this.cardId = this.getCardId();

    try {
      const { data } = await this.cardsStore.getCardData(this.cardId);

      this.cardImage = await this.cardsStore.getCardImage(data.cardType);
    } catch (error) {
      if (error === "Card not found") {
        this.$router.push("/404");
      } else {
        console.error("An error occurred:", error);
      }
    }

    this.saveVisitedCard();

    if (this.accountId) {
      await this.accountStore.getAccountData(this.accountId).then((data) => {
        if (this.isBusinessAdmin) {
          this.businessId = data.belongToBusinessObjId;
        }
      });
    }

    const authenticatedUser = this?.accountStore?.user ?? null;

    if (authenticatedUser) {
      this.accountId = authenticatedUser.accountId;
      const allPages = this?.accountStore?.user?.pages ?? null;

      // Error handling / fallback profile image
      if (allPages && allPages.length > 0) {
        this.getProfileImage(allPages);
      }
    }

    if (this.isBusinessAdmin) {
      this.getBusinessUser();
    }

    await this.redirectSubdomain();
  },
  computed: {
    filteredBusinessUsers() {
      const filteredArray = this.businessUsersStore.businessUsers.filter(
        (user: BusinessUser) => {
          const fullName = `${user.fname} ${user.lname}`;
          const email = user.email;
          const functionTitle = user.functionUser;
          const searchQueryLower = this.searchQuery.toLowerCase();

          return (
            fullName.toLowerCase().includes(searchQueryLower) ||
            email.toLowerCase().includes(searchQueryLower) ||
            functionTitle?.toLowerCase().includes(searchQueryLower)
          );
        }
      );

      // Reverse the filteredArray
      const reversedArray = filteredArray.slice().reverse();
      return reversedArray;
    },
    isAuthenticated() {
      return this.accountStore.user !== null;
    },
    isBusinessAdmin() {
      return (
        this.accountStore.user &&
        this.accountStore.user.isBusinessUser &&
        this.accountStore.user.role === "admin"
      );
    },
  },
  methods: {
    async getBusinessUser() {
      await this.businessUsersStore.getBusinessUsers(this.businessId);
    },
    async redirectSubdomain() {
      const fullPath = this.$route.fullPath;

      const IS_CONNECTFLOW_DOMAIN =
        window.location.host.includes("connectflow");

      if (IS_CONNECTFLOW_DOMAIN || IS_DEVELOPMENT) {
        return;
      }

      const fullConnectflowUrl = "https://connectflow.app" + fullPath;
      window.location.href = fullConnectflowUrl;
    },
    async addCardToPage() {
      try {
        this.isLoading = true;

        const accountPages = this.pagesStore.pages;
        const accountHasNoPage = !accountPages || accountPages.length < 1;

        if (accountHasNoPage) {
          await this.pagesStore
            .createPreFilledPage(this.accountId)
            .then(({ pageId }) => {
              this.pageId = pageId;
            });
        }

        if (this.cardId && this.pageId) {
          // This is the same & it can become shorter
          if (!this.accountId || this.accountId === 0) {
            await this.cardsStore.linkCardToPage(this.pageId, this.cardId);
          } else {
            await this.cardsStore.linkCardToPage(
              this.pageId,
              this.cardId,
              this.accountId
            );
          }

          localStorage.removeItem("cardId");
          this.$router.push("/account");

          this.$toast.add({
            severity: "success",
            summary: "Card added",
            detail: "Card is ready to use",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Failed to add card`,
            life: 5000,
          });
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Failed to add card: ${error}`,
          life: 5000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async assignCardToUser(userId: string) {
      if (!userId) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Failed to add card: User has no id`,
          life: 5000,
        });

        return;
      }

      this.businessCardsStore
        .linkCardToUser(this.cardId, userId)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successfull",
            detail: `Card is linked to user`,
            life: 5000,
          });
          this.$router.push(`/business/users/user/${userId}`);
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Failed to add card: ${error}`,
            life: 5000,
          });
        });

      localStorage.removeItem("cardId");
    },
    getCardId() {
      const rawCardId = this.$route.params.id;

      if (Array.isArray(rawCardId)) {
        console.error("Unexpected array for cardId:", rawCardId);
        return;
      }

      return rawCardId.toString();
    },
    async getProfileImage(allPages) {
      this.pageId = allPages[0]?.pageId?.toString();
      const pageData = await this.pagesStore.getPrivatePage(this.pageId);

      this.profileImage = pageData?.profileImageUrl;
    },
    saveVisitedCard() {
      localStorage.setItem("cardId", this.cardId);
    },
    removeCard() {
      localStorage.removeItem("cardId");
      this.$router.push("/account");
    },
    async applySubdomain(subdomain: string) {
      const fullPath = this.$route.fullPath;
      const host = window.location.host;
      const isCurrentHostConnectflow = host === "connectflow.app";
      const isCurrentHostTest = host === "frontend-test.connectflow.app";

      if (IS_DEVELOPMENT || isCurrentHostTest) {
        return;
      }

      if (!subdomain) {
        if (isCurrentHostConnectflow) {
          return;
        }

        const fullConnectflowUrl = "https://connectflow.app" + fullPath;
        window.location.href = fullConnectflowUrl;

        return;
      }

      if (host.includes(subdomain)) {
        return;
      }

      const fullCustomUrl = "http://" + subdomain + fullPath;

      window.location.href = fullCustomUrl;
    },
  },
};
</script>

<template>
  <div class="container">
    <div v-if="isLoading" class="spinner">
      <PVProgressSpinner />
    </div>
    <main>
      <div class="claim-card-header">
        <h1>Welcome</h1>
        <h2>to ConnectFlow</h2>
      </div>

      <img :src="this.cardImage" alt="" height="auto" :draggable="false" />

      <div v-if="!isAuthenticated" style="width: 100%">
        <ArticleListContainer title="Activate card" avatar="url">
          <ArticleListItem
            class="no-ellipsis"
            title="Create account"
            subtitle="Signup and add card"
            link="/sign-up"
            :isAvatar="false"
            :showIcon="false"
            :isHighlighted="true"
            icon="pi pi-user"
          />
          <ArticleListItem
            title="Login"
            subtitle="Login and add card"
            link="/login"
            :isAvatar="false"
            :showIcon="false"
            icon="pi pi-user"
          />
        </ArticleListContainer>
      </div>

      <div v-else-if="isAuthenticated && isBusinessAdmin" style="width: 100%">
        <ArticleListContainer title="Assign card" avatar="url">
          <span class="p-input-icon-left input-icon full-width">
            <i class="pi pi-search" />
            <PVInputText
              class="p-inputtext p-component full-width"
              v-model="this.searchQuery"
              placeholder="Find user"
            />
          </span>

          <ArticleListItem
            v-for="user in filteredBusinessUsers"
            :key="user.userId"
            :title="`${user.fname} ${user.lname}`"
            :subtitle="user.email"
            :imageUrl="user.image"
            :isAvatar="true"
            :hasIcon="false"
            :showIcon="false"
            @click="assignCardToUser(user.userID)"
          />
          <p v-if="filteredBusinessUsers.length === 0">No users found</p>
        </ArticleListContainer>
      </div>

      <div v-else style="width: 100%">
        <ArticleListContainer title="Activate card" avatar="url">
          <ArticleListItem
            title="Add card"
            :subtitle="this?.accountStore?.user?.email"
            :imageUrl="this.profileImage"
            :isAvatar="true"
            :hasIcon="false"
            :showIcon="false"
            :isHighlighted="true"
            @click="addCardToPage"
          />
          <ArticleListItem
            class="no-ellipsis"
            title="Create account"
            subtitle="Signup and add card"
            link="/sign-up"
            :isAvatar="false"
            :showIcon="false"
            icon="pi pi-user"
          />
          <ArticleListItem
            @click="this.authStore.logout()"
            title="Login"
            subtitle="Login and add card"
            link="/login"
            :isAvatar="false"
            :showIcon="false"
            icon="pi pi-user"
          />
        </ArticleListContainer>
      </div>
      <div class="remove-container">
        <PVButton
          icon="pi pi-times"
          aria-label="Close page"
          class="btn remove-btn"
          @click="removeCard"
        />
        <p>Close card</p>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: move-up-50 0.8s ease 0s 1 normal;
}

main {
  max-width: 500px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 100px;
}

.claim-card-header {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  animation: move-up-50 0.8s ease 0s 1 normal;
}

img {
  animation: card-flip 1s cubic-bezier(0, 0, 0, 1) forwards;
  width: 100%;
  filter: drop-shadow(0px 25px 12px #00000005);
  border-radius: 15px;
  margin-bottom: 50px;

  height: auto;
}

h1 {
  margin: 0;
  line-height: 24px;
}

h2 {
  color: #222222c2;
  line-height: initial;
  font-weight: 600;
}
.spinner {
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%) scale(1.5);
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
}

.p-input-icon-left {
  position: relative;
  display: flex;
  align-items: center;
}

.p-input-icon-left i {
  position: absolute;
  left: 8px;
  pointer-events: none;
}

.p-inputtext {
  padding-left: 32px;
}

.full-width {
  width: 100%;
}
</style>
