<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1211_807)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.11823 6.6549C5.67523 7.02708 6.33009 7.22573 7 7.22573C7.89831 7.22573 8.75983 6.86888 9.39504 6.23368C10.0302 5.59847 10.3871 4.73695 10.3871 3.83864C10.3871 3.16873 10.1884 2.51387 9.81627 1.95687C9.44409 1.39986 8.91509 0.965728 8.29618 0.709367C7.67727 0.453006 6.99624 0.38593 6.33921 0.516622C5.68218 0.647314 5.07865 0.969903 4.60496 1.4436C4.13127 1.91729 3.80868 2.52081 3.67798 3.17785C3.54729 3.83488 3.61437 4.51591 3.87073 5.13482C4.12709 5.75373 4.56122 6.28273 5.11823 6.6549ZM5.87094 2.14888C6.20514 1.92557 6.59806 1.80638 7 1.80638C7.53899 1.80638 8.0559 2.02049 8.43702 2.40161C8.81814 2.78274 9.03226 3.29965 9.03226 3.83864C9.03226 4.24058 8.91307 4.6335 8.68976 4.9677C8.46645 5.3019 8.14906 5.56238 7.77771 5.7162C7.40636 5.87001 6.99774 5.91026 6.60352 5.83184C6.2093 5.75343 5.84719 5.55988 5.56297 5.27566C5.27876 4.99144 5.0852 4.62933 5.00679 4.23511C4.92837 3.84089 4.96862 3.43227 5.12244 3.06092C5.27625 2.68958 5.53673 2.37218 5.87094 2.14888ZM12.8462 13.3474C12.9727 13.474 13.1436 13.5461 13.3226 13.5484C13.5015 13.5461 13.6725 13.474 13.799 13.3474C13.9255 13.2209 13.9977 13.0499 14 12.871C14 8.58069 9.09548 8.58069 7 8.58069C4.90452 8.58069 0 8.58069 0 12.871C0 13.0507 0.0713707 13.223 0.198411 13.35C0.325452 13.4771 0.497757 13.5484 0.677419 13.5484C0.857082 13.5484 1.02939 13.4771 1.15643 13.35C1.28347 13.223 1.35484 13.0507 1.35484 12.871C1.35484 11.1097 2.31226 9.93553 7 9.93553C11.6877 9.93553 12.6452 11.1097 12.6452 12.871C12.6475 13.0499 12.7196 13.2209 12.8462 13.3474Z"
        fill="#222222"
      />
    </g>
    <defs>
      <clipPath id="clip0_1211_807">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
