<script lang="ts">
import NavigationIcon from "./NavigationIcon.vue";
import IconLogout from "@/components/icons/business/IconLogout.vue";
import {
  useAuthStore,
  useAccountStore,
  useBusinessSettingsStore,
} from "@/stores";

import {
  isEmpty,
  businessAdminRoutes,
  businessEditorRoutes,
  businessViewerRoutes,
} from "@/helpers";

export default {
  data() {
    return {
      //   companyImage: "https://connectflow.app/favicon.ico",
      authStore: useAuthStore(),
      accountStore: useAccountStore(),
      businessSettingsStore: useBusinessSettingsStore(),
      businessId: "",
      userRole: "",
    };
  },
  components: {
    NavigationIcon,
    IconLogout,
  },
  computed: {
    companyName() {
      if (this.businessSettingsStore?.businessSettings?.companyName) {
        return this.businessSettingsStore.businessSettings.companyName;
      } else if (this.accountStore?.user?.companyName) {
        return this.accountStore.user.companyName;
      } else {
        return "Company name";
      }
    },
    navigationRoutes() {
      if (this.userRole === "admin") {
        localStorage.setItem("isbusinessAdminRoutes", "true");
        return businessAdminRoutes;
      } else if (this.userRole === "editor") {
        return businessEditorRoutes;
      } else {
        return businessViewerRoutes;
      }
    },
  },
  async created() {
    await this.getAccountInformation();
    await this.setAccountBusinessToSettingsBusiness();
  },
  mounted() {
    if (this.accountStore.isUserEditor) {
      businessEditorRoutes[1].title = this.accountStore.user.fname;
      businessEditorRoutes[1].url = `/business/users/user/${this.accountStore.user.userID}`;
    }
    this.userRole = this?.accountStore?.user?.role ?? "viewer";
  },
  methods: {
    async getAccountInformation() {
      const userInfo = this.accountStore.user;

      if (!userInfo) {
        this.authStore.logout();
        return;
      }

      if (!this.businessId) {
        this.businessId = userInfo.belongToBusinessObjId;
      }

      if (isEmpty(this.businessSettingsStore.businessSettings)) {
        await this.businessSettingsStore.getBusinessSettings(this.businessId);
      }
    },
    isRouteActive(routeUrl: string) {
      // Check if the current route's path matches the provided routeUrl
      return this.$route.path === routeUrl;
    },
    logout() {
      this.authStore.logout(true);
    },
    async setAccountBusinessToSettingsBusiness() {
      const businessSettings = this.businessSettingsStore.businessSettings;
      if (isEmpty(businessSettings)) {
        await this.businessSettingsStore.getBusinessSettings(this.businessId);
      }

      const settingsCompanyName = businessSettings?.companyName;

      if (settingsCompanyName) {
        return;
      }

      const accountCompanyName = this?.accountStore?.user?.companyName;

      if (!accountCompanyName) {
        return;
      }

      this.businessSettingsStore.setBusinessSettings(this.businessId, {
        companyName: accountCompanyName,
      });
    },
  },
};
</script>

<template>
  <nav>
    <a
      :href="this.accountStore.isUserAdmin ? '/business/settings' : '/'"
      class="heading"
    >
      <div class="heading-left">
        <img
          v-if="this.businessSettingsStore.businessSettings.logoIcon"
          :src="this.businessSettingsStore.businessSettings.logoIcon"
          class="image"
        />
        <img
          v-else
          src="https://connectflow.app/favicon.ico"
          title="ConnectFlow"
          class="image"
        />
      </div>
      <div class="heading-right menu-collapsed">
        <h1>
          {{ companyName }}
        </h1>
        <h2>ConnectFlow</h2>
        <!--
        <h2>ID: {{ businessId }}</h2>
        -->
      </div>
    </a>
    <ul>
      <li v-for="(route, index) in navigationRoutes" :key="index">
        <router-link
          :to="route.url"
          :class="{ active: isRouteActive(route.url) }"
          :title="route.title"
        >
          <NavigationIcon :icon="route.icon" />
          <span class="menu-collapsed">
            {{ route.title }}
          </span>
        </router-link>
      </li>
      <li>
        <button @click="logout()" title="Logout" class="logout-mobile">
          <IconLogout />
        </button>
      </li>
    </ul>
    <div class="footer">
      <button @click="logout()" title="Logout">
        <IconLogout />
        <span class="menu-collapsed"> Logout </span>
      </button>
      <p class="connectflow">Account ID: {{ businessId }}</p>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  background: white;
  padding: 18px;
  min-height: 100vh;
  height: 100%;
  z-index: 2;
  position: fixed;

  ul {
    list-style: none;
    padding-left: 0;
    width: 100%;
    animation: move-up-50 1s cubic-bezier(0, 0, 0, 1) forwards;

    li {
      width: 100%;
    }
  }

  a {
    font-size: 14px;
    text-decoration: none;
    color: #222;
    font-weight: 600;
    padding: 12px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.2s;

    user-select: none;
    -webkit-user-drag: none;

    span {
      font-weight: 600;
    }

    &.active {
      background-color: #222;
      filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1));
      box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.3);
      color: white;

      .sidebar-icon {
        filter: invert(1);
      }
      &:hover {
        background-color: #222;
        filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.2));
        color: white;
      }
    }

    &:hover {
      color: #222;
      background-color: var(--color-hover);
      filter: invert(0);
      transform: scale(1.01);
      transition: 0.2s;
    }
  }

  .heading-right h1 {
    padding: 0;
  }
}

.heading {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 12px;

  &:hover {
    background-color: var(--color-grey-hover);
    text-decoration: none;
  }

  h1 {
    font-size: 17.5px;
    font-weight: 600;
    line-height: initial;
  }

  h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: initial;
  }
}

.logout-mobile {
  display: none;
}

.image {
  width: 40px;
  height: 40px;
  //   background-color: var(--color-grey);
  border-radius: 50%;
  display: flex;
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.15));
}

.footer {
  position: absolute;
  bottom: 15px;
  left: 12px;
  padding: 12px;

  &:hover {
    text-decoration: none !important;
  }
}

.menu-collapsed {
  display: none;

  @media screen and (min-width: 1000px) {
    display: block;
  }
}

// Mobile menu

@media screen and (max-width: 487px) {
  nav {
    display: flex;
    align-items: center;
    min-height: 0;
    height: auto;
    min-width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 10;
    gap: 24px;
    border-top: 1px solid var(--color-grey);

    ul {
      display: flex;
      align-items: center;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .heading {
    margin-bottom: 0;
  }

  .heading-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer {
    display: none;
  }

  .logout-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
  }
}

button {
  border: none;
  background-color: transparent;
  display: flex;
  gap: 10px;
  text-decoration: none;
}

button:hover {
  cursor: pointer;
  text-decoration: none;
}

.connectflow {
  border: none;
  background-color: transparent;
  display: none;
  gap: 10px;
  color: var(--color-text-grey);
  font-size: 14px;
  font-weight: 600;
  padding-left: 6px;
  padding-top: 10px;

  @media screen and (min-width: 1000px) {
    display: flex;
  }
}
</style>
