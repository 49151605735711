<template>
  <main>
    <h1>404</h1>
    <h2>Page not found</h2>

    <a class="back-button" href="/account">Back to account</a>
  </main>
</template>

<style scoped>
main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-button {
  color: #222;
  text-decoration: none;
  font-weight: 600;
}
</style>
