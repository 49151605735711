import Cookies from "universal-cookie";

const cookies = new Cookies();
const isDev = import.meta.env.DEV;

const isSsl = isDev ? false : location.protocol.includes("https");
const domain = isDev ? "" : ".connectflow.app";
const tokenExpiresIn = 60 * 60 * 1000;

const tokenExpirationDate = new Date();
tokenExpirationDate.setTime(tokenExpirationDate.getTime() + tokenExpiresIn);

const refreshExpirationDate = new Date();
refreshExpirationDate.setDate(refreshExpirationDate.getDate() + 1);

export const setAccessTokenCookie = (accessTokenValue: string) => {
  cookies.set("token", accessTokenValue, {
    secure: isSsl,
    sameSite: "strict",
    path: "/",
    expires: tokenExpirationDate,
    domain: domain,
  });
};

export const setRefreshTokenCookie = (refreshTokenValue: string) => {
  cookies.set("refresh_token", refreshTokenValue, {
    secure: isSsl,
    sameSite: "strict",
    path: "/",
    expires: refreshExpirationDate,
    domain: domain,
  });
};
