<script lang="ts">
import IconWorld from "@/components/icons/IconWorld.vue";

import { IS_DEVELOPMENT } from "@/helpers";

import type { DNSApiResponseObject } from "@/types";

import { useBusinessSettingsStore } from "@/stores";

import DNSFalse from "@/components/icons/business/dns/DNSFalse.vue";
import DNSTrue from "@/components/icons/business/dns/DNSTrue.vue";

export default {
  data() {
    return {
      businessSettingsStore: useBusinessSettingsStore(),
      subdomainValue: "",
      isDNSLinked: false,
      isDNSLoaded: false,
      isDomainConfigured: true,
    };
  },
  components: {
    IconWorld,
    DNSFalse,
    DNSTrue,
  },
  async created() {
    this.subdomainValue = this.businessSettingsStore.businessSettings.subdomain;

    if (this.subdomainValue) {
      await this.getDNSDataFromUrl();

      this.isDomainConfigured = await this.isSubdomainConfigured(
        this.subdomainValue
      );
    }
  },
  methods: {
    async getDNSDataFromUrl() {
      const API_URL =
        "https://networkcalc.com/api/dns/lookup/" + this.subdomainValue;

      if (IS_DEVELOPMENT) {
        return;
      }

      try {
        const response = await fetch(API_URL, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          this.handleApiResponse(result);
        } else {
          const errorText = await response.text();
          console.error("Error:", errorText);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async isSubdomainConfigured(subdomain: string) {
      const rawUrl = "https://caminoagency.com/ingress/?domain=";

      try {
        const response = await fetch(rawUrl + subdomain);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.ingress === "true") {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        return false; // or throw the error if you want to handle it differently
      }
    },
    handleApiResponse(result: DNSApiResponseObject[]) {
      const index = result.records.A.findIndex(
        (item) => item.address === "143.198.250.45"
      );

      if (index === -1) {
        this.isDNSLoaded = true;
        return;
      }

      this.isDNSLinked = true;
      this.isDNSLoaded = true;
    },
  },
  computed: {
    domainName() {
      return (
        this.subdomainValue.split(".")[1] +
        "." +
        this.subdomainValue.split(".")[2]
      );
    },
  },
};
</script>

<template>
  <div class="dns">
    <div class="heading">
      <div class="image">
        <IconWorld />
      </div>
      <div class="text">
        <p class="title">DNS</p>
        <p class="subtitle">
          {{ domainName }}
        </p>
      </div>
      <div class="action-container">
        <div class="action" v-if="isDNSLoaded" title="DNS of your domain">
          <DNSTrue v-if="this.isDNSLinked" class="dns-icon" />
          <DNSFalse v-else class="dns-icon" />
          <p>You</p>
        </div>
        <div class="action" title="Configuration on the ConnectFlow server">
          <DNSTrue v-if="this.isDomainConfigured" class="dns-icon" />
          <DNSFalse v-else class="dns-icon" />
          <p>We</p>
        </div>
      </div>
    </div>
    <span>Name</span>
    <p>{{ subdomainValue.split(".")[0] }}</p>
    <span>Type</span>
    <p>A</p>
    <span>TTL</span>
    <p>3600</p>
    <span>Value</span>
    <p>143.198.250.45</p>
  </div>
</template>

<style lang="scss" scoped>
.action-container {
  position: absolute;
  right: 25px;
  display: flex;
  gap: 52px;
}
.dns {
  padding: 12px 14px 14px 12px;
}
.heading {
  display: flex;
  flex-direction: row;
  text-align: left;
  background-color: var(--color-white);
  border-radius: 6px;
  align-items: center;
  margin-bottom: 18px;
  gap: 10px;

  .action {
    display: flex;
    flex-direction: column;
    position: relative;

    p {
      font-size: 1.1em;
      position: absolute;
      bottom: 33.333%;
      right: 50%;
      transform: translate(50%, 50%);
    }
  }

  //   .action {
  //     position: absolute;
  //     right: 20px;
  //   }
}

.image {
  display: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey);
  border-radius: 50%;
}

.text {
  display: flex;
  flex-direction: column;
}

.text p {
  margin: 0;
  line-height: 1.25em;
}

p.title {
  font-size: 17.5px;
  font-weight: 600;
  color: #222; // Update this globally
}

p.subtitle {
  color: #222222bf;
  font-size: 14px;
  font-weight: normal;
}

span {
  color: var(--color-text-grey);
  font-size: 12px;
  font-weight: 600;
}

p {
  font-size: 17.5px;
  font-weight: 600;
}

.dns-icon {
  width: 20px;
}
</style>
