<script lang="ts">
import { defineAsyncComponent } from "vue";
import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListItem from "@/components/List/ListItem/ArticleListItem.vue";
import ArticleListActionItem from "@/components/List/ListItem/ArticleListActionItem.vue";

import InputWrapper from "@/components/Input/InputWrapper.vue";

import type { BusinessUser } from "@/stores/business/types";

import {
  useBusinessUsersStore,
  useAccountStore,
  useBusinessCardsStore,
} from "@/stores";

import { availableUserRoles } from "@/helpers";

export default {
  data() {
    return {
      goToEditUser: false,
      businessId: "",
      businessUsersStore: useBusinessUsersStore(),
      accountStore: useAccountStore(),
      businessCardsStore: useBusinessCardsStore(),
      isAddUserPopupVisible: false,
      searchQuery: "",
      newUser: {
        fname: "",
        lname: "",
        role: "viewer",
        email: "",
      },
    };
  },
  async created() {
    this.businessId = this?.accountStore?.user?.belongToBusinessObjId ?? "";
    await this.getBusinessUsers();
  },
  components: {
    ArticleListContainer,
    ArticleListItem,
    InputWrapper,
    ArticleListActionItem,
    PVDropdown: defineAsyncComponent(() => import("primevue/dropdown")),
  },
  computed: {
    availableRoles() {
      return availableUserRoles;
    },
    filteredUsers() {
      const filteredArray = this.businessUsersStore.businessUsers.filter(
        (user: BusinessUser) => {
          const fullName = `${user.fname} ${user.lname}`;
          const email = user.email;
          const functionTitle = user.functionUser;
          const searchQueryLower = this.searchQuery.toLowerCase();

          return (
            fullName.toLowerCase().includes(searchQueryLower) ||
            email.toLowerCase().includes(searchQueryLower) ||
            functionTitle?.toLowerCase().includes(searchQueryLower)
          );
        }
      );

      // Reverse the filteredArray
      const reversedArray = filteredArray.slice().reverse();
      return reversedArray;
    },
  },
  methods: {
    async sendVirtualCardMailToAllUsers() {
      this.$confirm.require({
        message:
          "Are you sure you want to send an email with their virtual card to all your users?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessCardsStore
            .sendVirtualCardToAllUsers(this.businessId)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: `Virtual cards are sent to users`,
                life: 5000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: `Something went wrong: ${error}`,
                life: 5000,
              });
            });
        },
      });
    },
    getSubtitle(user: BusinessUser) {
      if (user.functionTitle !== null && user.functionTitle !== undefined) {
        return `${user.functionTitle}`;
        // return `${user.functionUser} • ${user.userID}`;
      } else {
        // return user.userID;
      }
    },
    async addUserToBusiness() {
      if (!this.businessId) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Something went wrong. Try again`,
          life: 5000,
        });
        return;
      }

      this.newUser.email = this.newUser.email.toLowerCase();

      await this.businessUsersStore
        .addUserToBusiness(this.businessId, this.newUser)
        .then(async (data) => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Account is created",
            life: 5000,
          });
          this.isAddUserPopupVisible = false;
          this.getBusinessUsers();
          this.resetInputValues();

          await this.createVirtualCardForUser(data.user.userID);

          if (this.goToEditUser) {
            const userId = data.user.userID;

            this.$router.push(`/business/users/user/${userId}`);

            this.goToEditUser = false;
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 5000,
          });
        });
    },
    async createVirtualCardForUser(userId: string) {
      try {
        await this.businessCardsStore.createVirtualCardBusiness(userId);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Something went wrong: ${error}`,
          life: 5000,
        });
      }
    },
    resetInputValues() {
      this.newUser.fname = "";
      this.newUser.lname = "";
      this.newUser.email = "";
    },
    async getBusinessUsers() {
      if (!this.businessId) {
        this.$router.push("/");
        return;
      }

      await this.businessUsersStore.getBusinessUsers(this.businessId);
    },
  },
};
</script>

<template>
  <div class="users-container">
    <PVPopup
      class="popup"
      v-model:visible="isAddUserPopupVisible"
      modal
      dismissableMask
      @submit.prevent="addUserToBusiness"
      :draggable="false"
      header="New user"
    >
      <form id="add-user-popup" ref="addUserForm">
        <InputWrapper
          labelText="First name"
          labelRef="firstName"
          v-model="newUser.fname"
          variant="grey"
          isRequired
        />

        <InputWrapper
          labelText="Last name"
          labelRef="lastName"
          v-model="newUser.lname"
          variant="grey"
          isRequired
        />
        <InputWrapper
          labelText="Email"
          labelRef="email"
          v-model="newUser.email"
          variant="grey"
          isRequired
        />
        <ArticleListActionItem
          title="Role"
          subtitle="Select a role"
          :isAvatar="false"
          icon="pi pi-link"
          :hasIconBackground="true"
        >
          <PVDropdown
            v-model="newUser.role"
            :options="this.availableRoles"
            optionLabel="name"
            optionValue="code"
            placeholder="Select role"
          />
        </ArticleListActionItem>
      </form>
      <template #footer>
        <div class="btn-user-container">
          <PVButton
            class="btn btn-primary"
            label="Add user"
            autofocus
            form="add-user-popup"
            type="submit"
          />
          <PVButton
            class="btn btn-grey"
            label="Add and edit user"
            form="add-user-popup"
            type="submit"
            @click="goToEditUser = true"
            text
          />
        </div>
      </template>
    </PVPopup>
    <div class="heading">
      <span class="p-input-icon-left input-icon full-width">
        <i class="pi pi-search" />
        <PVInputText
          class="p-inputtext p-component"
          v-model="this.searchQuery"
          placeholder="Find user"
        />
      </span>

      <div class="virtual-card-container" v-if="filteredUsers.length > 1">
        <PVButton
          class="btn btn-secondary send-cards"
          label="Send virtual cards"
          @click="sendVirtualCardMailToAllUsers"
        />
      </div>

      <PVButton
        class="btn btn-primary add add-user"
        label="Add user"
        icon="pi pi-plus"
        @click="isAddUserPopupVisible = true"
      />
    </div>
    <main>
      <ArticleListContainer
        title="Users"
        avatar="url"
        :max-width="true"
        v-if="filteredUsers.length > 0"
      >
        <ArticleListItem
          v-for="user in filteredUsers"
          :key="user.userID"
          :title="`${user.fname} ${user.lname}`"
          :subtitle="this.getSubtitle(user)"
          :link="`/business/users/user/${user.userID}`"
          :isAvatar="true"
          :imageUrl="user.image"
          icon="pi pi-user"
        />
      </ArticleListContainer>
      <p v-if="filteredUsers.length < 1">
        Can't find a user, try another search term
      </p>
    </main>
  </div>
</template>

<style scoped>
button.add-user {
  width: 200px;
}

button.send-cards {
  width: 180px;
}

.users-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.p-input-icon-left {
  position: relative;
  display: flex;
  align-items: center;
}

.p-input-icon-left .pi {
  position: absolute;
  left: 10px;
  font-size: 1rem;
}

.p-input-icon-left input {
  padding-left: 40px;
}

main {
  margin-top: 60px;
  animation: move-up-100 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.btn-user-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
</style>
