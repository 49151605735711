<script lang="ts">
import { defineAsyncComponent } from "vue";

import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListActionItem from "@/components/List/ListItem/ArticleListActionItem.vue";

import { ACCOUNT_ID } from "@/helpers";

import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";

import {
  useCardsStore,
  usePagesStore,
  useAccountStore,
  useBusinessCardsStore,
  useBusinessUsersStore,
} from "@/stores";

import { isAppleDevice, isMobileDevice } from "@/helpers";

import appleWalletImg from "@/assets/img/cards/apple-wallet.svg";
import googleWalletImg from "@/assets/img/cards/google-wallet.svg";

export default {
  data() {
    return {
      showAddToWalletPopup: false,
      card: null,
      cardImage: "",
      walletUrl: "",
      linkedPage: null,
      cardsStore: useCardsStore(),
      pagesStore: usePagesStore(),
      accountStore: useAccountStore(),
      businessCardsStore: useBusinessCardsStore(),
      businessUsersStore: useBusinessUsersStore(),
      isCardFrozen: false,
      showSpinner: false,
      selectedPage: null,
      isBusinessCard: false,
      businessUser: {},
      selectedUser: {},
      savedSelectedUser: {},
      accountId: ACCOUNT_ID,
    };
  },
  components: {
    ArticleListContainer,
    ArticleListActionItem,
    IconArrowLeft,
    PVDropdown: defineAsyncComponent(() => import("primevue/dropdown")),
  },
  async created() {
    this.accountId = localStorage.getItem("accountId") ?? undefined; // Workaround, need to have this
    this.isBusinessCard =
      this.accountStore?.user?.belongToBusinessObjId !== null;
    const cardId = this.$route.params.id;

    const cardData = await this.cardsStore.getCardData(cardId.toString());

    this.card = cardData.data;

    if (!this.isBusinessCard) {
      if (this.card?.pageId === null) {
        this.$router.push(`/c/${cardId}`);
      }
    }

    if (this.isBusinessCard) {
      if (this.card?.userId === null) {
        this.$router.push(`/c/${cardId}`);
      }
      await this.getBusinessData();
    }

    if (this.card.accountId !== this.accountId && !this.isBusinessCard) {
      this.$router.push(`/c/${cardId}`);
    }

    this.cardImage = await this.cardsStore.getCardImage(this.card?.cardType);
  },
  methods: {
    async getBusinessData() {
      await this.businessUsersStore
        .getBusinessUser(this.card.userId)
        .then((data) => {
          this.businessUser = data;
          this.selectedUser = data.fname + " " + data.lname;
          this.savedSelectedUser = this.selectedUser;
        });

      const businessId = this.accountStore.user.belongToBusinessObjId;
      await this.businessUsersStore.getBusinessUsers(businessId);
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this?.card?.uuid);
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        detail: `Card ID ${this.card.uuid} is copied to your clipboard.`,
        life: 5000,
      });
    },
    removeCard() {
      this.$confirm.require({
        message: "Are you sure you want to remove this card?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          this.showSpinner = true;
          await this.cardsStore
            .deleteCard(this?.card?.uuid)
            .then(() => {
              this.showSpinner = false;
              this.$router.push("/account");
              this.$toast.add({
                severity: "success",
                summary: "Confirmed",
                detail: `Card with id ${this?.card?.uuid} is removed`,
                life: 3000,
              });
            })
            .catch((error) => {
              this.showSpinner = false;
              this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: `Something went wrong: ${error}`,
                life: 3000,
              });
            });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Cancelled",
            detail: "Card is not removed",
            life: 3000,
          });
        },
      });
    },
    freezeCard() {
      //   if (this.isCardFrozen) {
      this.$confirm.require({
        message: `Are you sure you want to ${
          this.isCardFrozen ? "freeze" : "unfreeze"
        } this card?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.showSpinner = true;
          setTimeout(() => {
            // this.isCardFrozen = !this.isCardFrozen;
            this.showSpinner = false;
            this.$toast.add({
              severity: "success",
              summary: "Confirmed",
              detail: `Card with id ${this.card.uuid} is ${
                this.isCardFrozen ? "frozen" : "unfrozen"
              }`,
              life: 3000,
            });
          }, 1_500);
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Cancelled",
            detail: `Card is not ${this.isCardFrozen ? "frozen" : "unfrozen"}`,
            life: 3000,
          });
          this.isCardFrozen = !this.isCardFrozen;
        },
      });
      //   }
    },
    async addCardToWallet() {
      const cardId = this.$route.params.id;

      const domain = window.location.origin;

      const businessId = this?.businessUser?.belongToBusinessObjId ?? "";

      const standardWalletUrl = `${domain}/wallet/${cardId}`;
      const businessWalletUrl = `${domain}/wallet/${cardId}/${businessId}`;

      this.walletUrl = this.isBusinessCard
        ? businessWalletUrl
        : standardWalletUrl;

      if (!this.isMobileDevice) {
        this.showAddToWalletPopup = true;
        return;
      }

      this.$router.push(`/wallet/${cardId}`);
    },
    async sendWalletToUser() {
      await this.businessCardsStore
        .sendVirtualCardToUser(this.businessUser.userID)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Confirmed",
            detail: `Wallet card is sent to ${this.businessUser.email}`,
            life: 3000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Try again: ${error}`,
            life: 3000,
          });
        });
    },
    async assignCardToUser() {
      const userObject = this.businessUsersStore.businessUsers.find(
        (obj) => obj.email === this.selectedUser.email
      );

      const cardId = this.$route.params.id;

      if (!userObject?.userID) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Failed to add card: User has no id`,
          life: 5000,
        });

        return;
      }

      this.$confirm.require({
        message: `Are you sure you want link this card to ${this.selectedUser.fname} ${this.selectedUser.lname}`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessCardsStore
            .linkCardToUser(cardId, userObject.userID)
            .then(() => {
              this.savedSelectedUser = this.selectedUser;
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: `Card is linked to ${this.selectedUser.fname}`,
                life: 5000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: `Failed to add card: ${error}`,
                life: 5000,
              });
            });
        },
        reject: () => {
          this.selectedUser = this.savedSelectedUser;
        },
        onHide: () => {
          this.selectedUser = this.savedSelectedUser;

          // console.log(this.);
        },
      });
    },
  },
  computed: {
    walletImg() {
      if (isAppleDevice && isMobileDevice) {
        return appleWalletImg;
      }

      if (!isAppleDevice && isMobileDevice) {
        return googleWalletImg;
      }

      return googleWalletImg;
    },
    isMobileDevice() {
      return isMobileDevice;
    },
    isAppleDevice() {
      return isAppleDevice;
    },
  },
};
</script>

<template>
  <div v-if="showSpinner" class="spinner">
    <PVProgressSpinner />
  </div>
  <PVPopup
    header="Add to Wallet"
    v-model:visible="showAddToWalletPopup"
    dismissableMask
    :draggable="false"
    :modal="true"
    class="popup qr"
  >
    <div class="qr-code-container">
      <img
        class="qr-code"
        :src="`https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${walletUrl}`"
        draggable="false"
      />
      <p class="qr-text">Continue on your phone</p>
    </div>
  </PVPopup>
  <div class="container">
    <header class="card-header">
      <a
        :href="`${
          this.isBusinessCard
            ? '/business/users/user/' + this.businessUser.userID
            : '/account'
        }`"
      >
        <IconArrowLeft />
      </a>
      <h1>{{ card?.cardCategoryName }}</h1>
      <div class=""></div>
    </header>

    <main class="card-info">
      <img
        class="card-image"
        :src="this.cardImage"
        alt=""
        :draggable="false"
        height="auto"
      />

      <ArticleListContainer title="Actions" avatar="url">
        <ArticleListActionItem
          title="Card ID"
          :subtitle="card?.uuid"
          :isAvatar="false"
          icon="pi pi-hashtag"
          @click="copyToClipboard"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>

        <ArticleListActionItem
          v-if="!this.isBusinessCard"
          @click="this.$router.push(`/account/page/${card?.pageId}`)"
          title="Linked page"
          :subtitle="card?.pageId ?? 'None'"
          :isAvatar="false"
          icon="pi pi-link"
        >
        </ArticleListActionItem>

        <ArticleListActionItem
          v-else
          title="Linked user"
          :subtitle="card?.pageId ?? 'None'"
          :isAvatar="false"
          icon="pi pi-link"
        >
          <PVDropdown
            v-model="this.selectedUser"
            editable
            :options="this.businessUsersStore.businessUsers"
            :optionLabel="(user) => user.fname + ' ' + user.lname"
            placeholder="Select user"
            @change="assignCardToUser"
          />
        </ArticleListActionItem>

        <!-- <ArticleListActionItem
          title="Freeze card"
          subtitle="Temporarily unlink"

          :isAvatar="false"
          icon="pi pi-power-off"
        >
          <PVInputSwitch v-model="isCardFrozen" @change="freezeCard" />
        </ArticleListActionItem> -->
        <ArticleListActionItem
          title="Delete card"
          :isAvatar="false"
          icon="pi pi-trash"
          @click="removeCard"
        >
          <PVButton
            icon="pi pi-trash"
            aria-label="Remove"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
      </ArticleListContainer>

      <ArticleListContainer
        title="Add to Wallet"
        avatar="url"
        v-if="!this.isMobileDevice"
      >
        <ArticleListActionItem
          class="no-ellipsis"
          title="Add to Wallet"
          subtitle="Add this card to your digital wallet"
          :isAvatar="false"
          icon="pi pi-wallet"
          @click="addCardToWallet"
        >
          <PVButton
            icon="pi pi-plus-circle"
            aria-label="Add to wallet"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
        <ArticleListActionItem
          @click="sendWalletToUser"
          v-if="this.isBusinessCard"
          class="no-ellipsis"
          title="Send card to email"
          :subtitle="`Send card to ${this.businessUser.fname}`"
          :isAvatar="false"
          icon="pi pi-envelope"
        >
          <PVButton
            icon="pi pi-send"
            aria-label="Send to email"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
      </ArticleListContainer>

      <div class="wallet-container" v-if="this.isMobileDevice">
        <button @click="addCardToWallet">
          <img :src="this.walletImg" alt="" />
        </button>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/shared/views/detail.css";

.card-image {
  animation: card-flip 1s cubic-bezier(0, 0, 0, 1) forwards;
  width: 100%;
  filter: drop-shadow(0px 40px 26px #00000020);
  height: auto;
}

.card-header {
  margin-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 0px;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;

  a {
    color: var(--color-black);
    padding: 10px;
  }
}

.card-info {
  width: 100%;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 50px;
}

.spinner {
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%) scale(1.5);
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
}

main {
  padding: 0;
  row-gap: 54px;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.confirm-card-deletion-dialog {
  .p-dialog-footer {
    border: 1px solid red;
  }
}

.card-info section {
  margin-top: 48px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: auto;
  height: auto;
  flex-direction: column;
}
.qr-code {
  animation: zoom-in 1s cubic-bezier(0, 0, 0, 1) forwards;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  filter: drop-shadow(0px 30px 20px rgba(34, 34, 34, 0.1));
  user-select: none;
}

.qr-text {
  padding-top: 35px;
  padding-bottom: 20px;
  color: #222;
  font-family: var(--font-primary);
  font-size: 16px;
}

.wallet-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  button {
    margin: 0;
    border: none;
    background: transparent;

    &:active {
      transform: scale(0.98);
    }
    img {
      height: 55px;
      width: auto;
      flex: center;
    }
  }
}
</style>
