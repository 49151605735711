export async function formatLocationString(data) {
  const city =
    data.address.city_district ??
    data.address.city ??
    data.address.village ??
    null;
  const road = data.address.road ?? null;
  const country = data.address.country;

  let formatted = road ? `${road}, ` : "";

  if (city) {
    formatted += `${city}, `;
  }

  if (country) {
    formatted += `${country}`;
  }

  return formatted;
}
