import { defineStore } from "pinia";
import { fetchWrapper, BASE_URL } from "@/helpers";

import type { ConnectionState, Connection } from "../types";

export const useBusinessConnectionStore = defineStore({
  id: "business-connections",
  state: (): ConnectionState => ({
    connections: [] as Connection[],
  }),
  actions: {
    async getBusinessConnections(businessId: string) {
      const connections = await fetchWrapper.get(
        `${BASE_URL}/connect/business/list/${businessId}`
      );

      this.connections = connections.rows;

      return connections;
    },
    async getBusinessConnection(connectId: string) {
      return await fetchWrapper.get(`${BASE_URL}/connect/${connectId}`);
    },
    async getBusinessUserConnections(businessId: string, userId: string) {
      const { rows: connections } = await fetchWrapper.get(
        `${BASE_URL}/connect/business/${businessId}/${userId}`
      );

      return connections;
    },
    async createBusinessConnection(businessId: number, pageId: number, data) {
      return await fetchWrapper.post(
        `${BASE_URL}/connect/business/${businessId}/${pageId}`,
        data
      );
    },
    async updateBusinessUserConnections(connectId: string, data) {
      return await fetchWrapper.patch(
        `${BASE_URL}/connect/${connectId}`,data
      );
    },
    async deleteBusinessConnection(connectId: string) {
      return await fetchWrapper.delete(`${BASE_URL}/connect/${connectId}`);
    },
  },
});
