export const addPageItemArray = [
  {
    id: 0,
    title: "Website",
    subtitle: "Link",
    slug: "url",
  },
  {
    id: 1,
    title: "Email",
    subtitle: "Address",
    slug: "email",
  },
  {
    id: 2,
    title: "Phone",
    subtitle: "Number",
    slug: "phone",
  },
  {
    id: 3,
    title: "Address",
    subtitle: "Location",
    slug: "address",
  },
  {
    id: 4,
    title: "WhatsApp",
    subtitle: "Message",
    slug: "whatsapp",
  },
  {
    id: 5,
    title: "Message",
    subtitle: "Message",
    slug: "message",
  },
  {
    id: 6,
    title: "FaceTime",
    subtitle: "FaceTime",
    slug: "facetime",
  },
  {
    id: 7,
    title: "Calendly",
    subtitle: "Link",
    slug: "calendly",
  },
  {
    id: 7,
    title: "LinkedIn",
    subtitle: "Profile",
    slug: "linkedin",
  },
  {
    id: 8,
    title: "LinkedIn",
    subtitle: "Company",
    slug: "linkedin-company",
  },
  {
    id: 9,
    title: "Instagram",
    subtitle: "Profile",
    slug: "instagram",
  },
  {
    id: 10,
    title: "Snapchat",
    subtitle: "Profile",
    slug: "snapchat",
  },
  {
    id: 11,
    title: "TikTok",
    subtitle: "Profile",
    slug: "tiktok",
  },
  {
    id: 12,
    title: "Twitter",
    subtitle: "Profile",
    slug: "twitter",
  },
  {
    id: 13,
    title: "Facebook",
    subtitle: "Profile",
    slug: "facebook",
  },
  {
    id: 14,
    title: "YouTube",
    subtitle: "Channel",
    slug: "youtube",
  },
  {
    id: 15,
    title: "Twitch",
    subtitle: "Account",
    slug: "twitch",
  },
  {
    id: 16,
    title: "Telegram",
    subtitle: "Account",
    slug: "telegram",
  },
  /*
  {
    id: 16,
    title: "Discord Server",
    subtitle: "Server",
  },
  */
  {
    id: 17,
    title: "Pinterest",
    subtitle: "Profile",
    slug: "pinterest",
  },
  {
    id: 18,
    title: "Spotify",
    subtitle: "Profile",
    slug: "spotify",
  },

  /*

  {
    id: 19,
    title: "Apple Music",
    subtitle: "Profile",
    slug: "apple-music",
  },

  */

  {
    id: 20,
    title: "SoundCloud",
    subtitle: "Profile",
    slug: "soundcloud",
  },
  {
    id: 21,
    title: "Tikkie",
    subtitle: "Link",
    slug: "tikkie",
  },
  {
    id: 22,
    title: "PayPal",
    subtitle: "Link",
    slug: "paypal",
  },
];
