<script lang="ts">
import { slugify } from "@/helpers";
import IconEdit from "@/components/icons/IconEdit.vue";

import { useBusinessPagesStore, useAccountStore } from "@/stores";
import InputWrapper from "@/components/Input/InputWrapper.vue";

import type { Variable } from "@/types/business";

export default {
  components: {
    InputWrapper,
    IconEdit,
  },
  data() {
    return {
      pageId: "",
      businessPagesStore: useBusinessPagesStore(),
      accountStore: useAccountStore(),
      isAddFieldDialogVisible: false,
      isEditFieldDialogVisible: false,
      fieldData: {
        variableName: "",
        variablekey: "",
      },
      selectedVariable: {
        variableId: "",
        variableName: "",
        variablekey: "",
      },
      businessId: "",
      pageVariables: [],
    };
  },
  computed: {
    dynamicFieldLabel() {
      return (
        this.selectedPageItem.dynamicField.charAt(0).toUpperCase() +
        this.selectedPageItem.dynamicField.slice(1)
      );
    },
    computedLinkUrl() {
      return (itemType: string, itemData: any) => {
        const { linkUrl } = generatePlatformData(itemType, itemData);
        return linkUrl;
      };
    },
    computedInputType() {
      return (itemType: string) => {
        return getInputType(itemType);
      };
    },
    computedInputPattern() {
      return (itemType: string) => {
        return getInputPattern(itemType);
      };
    },
    computedInputPlaceholder() {
      return (itemType: string) => {
        return generatePlaceholder(itemType);
      };
    },
    computedInputTitle() {
      return (itemType: string) => {
        return generateInputTitle(itemType);
      };
    },
    currentPage() {
      return this.pagesStore.pages[0];
    },
  },
  async created() {
    this.businessId = this.accountStore.user.belongToBusinessObjId;

    await this.businessPagesStore.getPageVariables(this.businessId);
  },
  mounted() {
    if (!this.accountStore.isUserAdmin) {
      this.$router.push("/");
    }
  },
  methods: {
    slugifyLabel() {
      const isSelectedVariable = this.selectedVariable.variableId !== "";
      if (isSelectedVariable) {
        this.selectedVariable.variablekey = slugify(
          this.selectedVariable.variableName
        );
        return;
      }

      this.fieldData.variablekey = slugify(this.fieldData.variableName);
    },
    onCloseEditDialog(isEditDialogVisible: boolean) {
      // Handle the emitted value here, if needed
      this.isEditDialogVisible = isEditDialogVisible;
      this.pageItemData.itemData = "";
    },
    async getFields() {
      await this.pagesStore
        .getPageItems(this.pageId)
        .then((data) => {
          this.pagesStore.setPageItems(data.rows);
        })
        .catch((error) => {
          const noPageItemsOnPage = error === "Page Items are not found";

          if (noPageItemsOnPage) {
            this.pagesStore.setPageItems([]);
          }
        });
    },
    async createField() {
      await this.businessPagesStore.createPageVariable(
        this.businessId,
        this.fieldData
      );

      this.fieldData.variablekey = "";
      this.fieldData.variableName = "";

      this.isAddFieldDialogVisible = false;
    },
    async updateField() {
      const updatedData = {
        variableName: this.selectedVariable.variableName,
        variablekey: this.selectedVariable.variablekey,
      };

      await this.businessPagesStore
        .updatePageVariable(
          this.businessId,
          this.selectedVariable.variableId,
          updatedData
        )
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: `Field ${updatedData.variableName} is updated`,
            life: 5000,
          });
          this.isEditFieldDialogVisible = false;
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Field",
            detail: `Field ${updatedData.variableName} is not updated. ${error}`,
            life: 5000,
          });
        });
    },
    async deleteField() {
      this.$confirm.require({
        message: `Are you sure you want to remove the variable ${this.selectedVariable.variableName}? This could data on pages.`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await this.businessPagesStore.deletePageVariable(
            this.selectedVariable.variableId,
            this.businessId
          );
        },
        reject: () => {
          // don't update it (does this happen automatically?)
        },
      });

      this.isEditFieldDialogVisible = false;
    },
    copyFieldToClipboard(variableKey: string) {
      navigator.clipboard.writeText("{" + variableKey + "}");
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        detail: `Field ${variableKey} is copied to your clipboard.`,
        life: 5000,
      });
    },
    openEditFieldPopup(variable: Variable) {
      const { variableId, variableName, variablekey } = variable;

      this.selectedVariable.variableId = variableId;
      this.selectedVariable.variableName = variableName;
      this.selectedVariable.variablekey = variablekey;
      this.isEditFieldDialogVisible = true;
    },
  },
};
</script>

<template>
  <PVPopup
    v-model:visible="isAddFieldDialogVisible"
    modal
    dismissableMask
    :draggable="false"
    header="New field"
  >
    <form
      class="input-container"
      id="add-variable-form"
      @submit.prevent="createField"
    >
      <InputWrapper
        labelText="Label"
        labelRef="variableName"
        v-model="fieldData.variableName"
        variant="grey"
        isRequired
        @change="slugifyLabel"
      />
      <InputWrapper
        labelText="Field"
        labelRef=" variablekey"
        v-model="fieldData.variablekey"
        variant="grey"
        pattern="^[a-z0-9]+(-[a-z0-9]+)*$"
        title="Please a field without spaces (only hypens)"
        isRequired
      />
    </form>

    <template #footer>
      <div class="btn-container">
        <PVButton
          label="Cancel"
          class="btn btn-secondary"
          icon="pi pi-times"
          @click="
            isAddFieldDialogVisible = false;
            this.fieldData.variablekey = '';
            this.fieldData.variableName = '';
          "
        />
        <PVButton
          label="Save"
          class="btn btn-primary"
          autofocus
          type="submit"
          form="add-variable-form"
        />
      </div>
    </template>
  </PVPopup>
  <PVPopup
    v-model:visible="isEditFieldDialogVisible"
    modal
    dismissableMask
    :draggable="false"
    header="Edit field"
  >
    <form
      class="input-container"
      id="edit-variable-form"
      @submit.prevent="updateField"
    >
      <InputWrapper
        labelText="Label"
        labelRef="variableName"
        v-model="selectedVariable.variableName"
        variant="grey"
        isRequired
        @change="slugifyLabel"
      />

      <!--
      <InputWrapper
        labelText="Field"
        labelRef=" variablekey"
        v-model="selectedVariable.variablekey"
        variant="grey"
        pattern="^[a-z0-9]+(-[a-z0-9]+)*$"
        title="Please a field without spaces (only hypens)"
        isRequired
      /> -->
    </form>

    <template #footer>
      <div class="btn-container">
        <PVButton
          label="Delete"
          class="btn btn-secondary"
          icon="pi pi-trash"
          @click="deleteField"
        />
        <PVButton
          label="Save"
          class="btn btn-primary"
          autofocus
          type="submit"
          form="edit-variable-form"
        />
      </div>
    </template>
  </PVPopup>
  <div class="container">
    <h1>Fields</h1>

    <ul v-if="this.businessPagesStore.pageVariables.length > 0">
      <li
        v-for="(variable, index) in this.businessPagesStore.pageVariables"
        :key="index"
      >
        <article>
          <h2>Field label</h2>

          <div class="row" @click="openEditFieldPopup(variable)">
            <div class="inner-row">
              <h3>Label</h3>
              <p>{{ variable.variableName }}</p>
            </div>
            <PVButton
              class="btn btn-icon btn-custom"
              aria-label="Copy to clipboard"
            >
              <IconEdit />
            </PVButton>
          </div>

          <div
            :data-variablekey="variable.variablekey"
            class="row"
            @click="copyFieldToClipboard(variable.variablekey)"
          >
            <div class="inner-row">
              <h3>Field</h3>
              <p>
                <span>{{ "{" }}</span
                >{{ variable.variablekey }}<span>{{ "}" }}</span>
              </p>
            </div>
            <PVButton
              icon="pi pi-clone"
              aria-label="Copy to clipboard"
              class="btn btn-icon btn-custom"
            />
          </div>
        </article>
      </li>
    </ul>

    <div class="button-container">
      <PVButton
        class="btn btn-primary add"
        label="Add field"
        icon="pi pi-plus"
        @click="isAddFieldDialogVisible = true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
}

.btn-custom {
  width: 50px;
}

.input-container {
  display: flex;
  flex-direction: column;
}

h1 {
  margin: 40px 0 24px 0;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

article {
  background-color: var(--color-white);
  width: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 24px 0 48px 0;
  padding: 5px;
  transition: 0.2s;

  animation: move-up-100 0.8s cubic-bezier(0, 0, 0, 1) forwards;

  h2 {
    position: absolute;
    top: -22px;
    left: 6px;
    font-weight: 600;
    font-size: 12px;
    color: rgba(34, 34, 34, 0.65);
  }

  h3 {
    font-size: 12px;
    font-weight: 600;
    color: rgba(34, 34, 34, 0.5);
    line-height: 16px;
  }

  p {
    font-weight: 600;
    font-weight: 17.5px;
    color: #222;
    margin: 0;

    span {
      opacity: 0.5;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    padding: 10px 10px 10px 12px;
    transition: 0.2s;

    &:hover {
      background-color: var(--color-hover);
      cursor: pointer;
      transition: 0.2s;
    }
  }

  .inner-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .icon {
    height: 20px;
    width: 20px;
    background-color: salmon;
    border-radius: 50%;
  }

  &:hover {
    transition: 0.2s;
    box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.08);
  }
}

.button-container {
  animation: fade-in 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}
</style>
