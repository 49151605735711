import { useAuthStore } from "@/stores";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface RequestOptions extends RequestInit {
  headers: {
    [key: string]: string;
  };
  _retry?: boolean;
}

interface ResponseData {
  [key: string]: any;
}

interface ErrorResponse {
  message?: string;
}

interface ResponseWithData extends Response {
  data: ResponseData;
}

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
};

function request(method: HttpMethod) {
  return async (
    url: string,
    body?: object,
    headers: { [key: string]: string } = {},
    retry = false
  ): Promise<ResponseData> => {
    const requestOptions: RequestOptions = {
      method,
      headers: { ...authHeader(), ...headers },
      _retry: retry,
    };

    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }

    return fetch(url, requestOptions)
      .then(handleResponse)
      .catch(async (error) => {
        if (error === "Please authenticate" && !retry) {
          const refreshed = await refreshToken();
          if (refreshed) {
            return request(method)(url, body, headers, true);
          }
        }
        return Promise.reject(error);
      });
  };
}

// helper functions
function authHeader(): { [key: string]: string } {
  const accessToken = cookies.get("token");

  return { Authorization: `jwt ${accessToken}` };
}

async function refreshToken(): Promise<boolean> {
  const authStore = useAuthStore();
  try {
    await authStore.refreshToken();
    return true;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    return false;
  }
}

function handleResponse(response: Response): Promise<ResponseData> {
  return response.text().then((text) => {
    // This has some workaround, because backend is not using consistent messages. When having more time, I'll figure out what they did and change it. Inconsistent responses

    const successArray = [
      "successfull",
      "succesfull",
      "succesfully",
      "successfully",
    ];

    if (successArray.some((word) => text.includes(word))) {
      return text;
    }

    let data;

    try {
      data = JSON.parse(text);
    } catch (error) {
      return text;
    }

    const { user, logout } = useAuthStore();

    if (!response.ok) {
      alert("Error in response");
      if ([401, 403].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        logout();
      }

      const error: string =
        (data as ErrorResponse)?.message || response.statusText;
      return Promise.reject(error);
    }

    if (data && "msg" in data && data.msg === "Please authenticate") {
      localStorage.clear();
      localStorage.setItem(
        "returnUrl",
        window.location.pathname.toString() ?? ""
      );
      window.location.href = "/login";
    }

    if (
      data &&
      "msg" in data &&
      data.msg.startsWith("You are not authorized")
    ) {
      window.location.href = "/account";
    }

    if (data && "msg" in data && data.success !== true) {
      return Promise.reject(data.msg);
    }

    return data;
  }) as Promise<ResponseWithData>;
}
