export const publicRoutes = [
  "/login",
  "/reset-password",
  "/forgot-password",
  "/verify-email",
  "/account-verified",
  "/business/sign-up",
  "/sign-up",
  new RegExp("^/p/[^/]+$"), // matches /p/:id
  new RegExp("^/p/[^/]+/save$"), // matches /p/:id/save
  new RegExp("^/c/[^/]+$"), // matches /c/:id
  new RegExp("^/wallet/[^/]+$"), // matches /wallet/:cardId
  new RegExp("^/wallet/[^/]+/[^/]+$"), // matches /wallet/:cardId/:businessId
];

export function isCurrentRouteAPublicRoute(currentPathname: string) {
  return publicRoutes.some((route) =>
    typeof route === "string"
      ? route === currentPathname
      : route.test(currentPathname)
  );
}
