import { defineStore } from "pinia";
import { fetchWrapper, BASE_URL } from "@/helpers";

import type { CardsState, Card } from "../types";

export const useBusinessCardsStore = defineStore({
  id: "business-cards",
  state: (): CardsState => ({
    cards: [] as Card[],
  }),
  actions: {
    resetCardsData() {
      this.cards = [];
    },
    async getCardData(cardId: string) {
      return await fetchWrapper.get(`${BASE_URL}/card/${cardId}`);
    },
    async getAllCards(accountId: string) {
      const response = await fetchWrapper.get(
        `${BASE_URL}/card/getCards/${accountId}`
      );

      if (response.success) {
        const newCards = response.data.rows;

        for (const card of newCards) {
          this.cards.push(card);
        }
      }
    },
    async linkCardToUser(cardId: string, userId: string) {
      const response = await fetchWrapper.patch(`${BASE_URL}/card/${cardId}`, {
        userId,
      });

      return response;
    },
    async deleteCard(cardId: string) {
      await fetchWrapper.delete(`${BASE_URL}/card/${cardId}`);
    },
    async createVirtualCardBusiness(userId: string) {
      const { data } = await fetchWrapper.post(
        `${BASE_URL}/card/business/addVirtualCard/${userId}`
      );

      return data;
    },
    async sendVirtualCardToUser(userId: string) {
      await fetchWrapper.post(
        `${BASE_URL}/wallet/send-virtualCard-email/${userId}`
      );
    },
    async sendVirtualCardToAllUsers(businessId: string) {
      await fetchWrapper.post(
        `${BASE_URL}/wallet/send-virtualCard-emails/${businessId}`
      );
    },
  },
});
