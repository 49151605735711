<template>
  <li>
    <router-link :to="link" :target="newTab ? '_blank' : '_self'">
      <article @click="openModal">
        <div class="item-image-and-title">
          <div class="image">
            <img
              v-if="this.itemType === 'url'"
              :src="`https://icon.horse/icon/?uri=https://${this.itemData}&apikey=74b49a98-7587-4bcb-8123-6ef9d4d89838&size=small`"
            />
            <img v-else :src="imageUrl" height="200" width="200" />
          </div>

          <div class="info">
            <!-- <p class="title">{{ itemName }}</p> -->
            <p class="title" v-if="!isBusiness">{{ itemName }}</p>
            <p class="title" v-else>{{ this.localItemName }}</p>
            <p class="subtitle">{{ subtitle }}</p>
          </div>
        </div>

        <div class="action-group">
          <button v-if="!isEditing" tabindex="-1">
            <IconArrowRight />
          </button>
          <button v-if="isEditing" @click.prevent="openModal">
            <IconEdit />
          </button>
          <button
            v-if="isEditing"
            :class="{ editing: isEditing }"
            @click.prevent
          >
            <IconArrange class="handle" />
          </button>
        </div>
      </article>

      <!-- EditPageItemPopup -->
      <PVPopup
        header="Edit"
        v-model:visible="displayModal"
        dismissableMask
        :draggable="false"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
      >
        <form
          v-if="localItemType !== 'address'"
          id="page-item-form"
          @submit.prevent="savePageItem"
        >
          <InputWrapper
            labelText="Title"
            labelRef="firstName"
            v-model="this.localItemName"
            variant="grey"
            isRequired
          />

          <InputWrapper
            v-if="!isBusiness"
            :labelText="this.computedLabelText(this.localItemType)"
            labelRef="firstName"
            v-model="this.localItemData"
            variant="grey"
            :type="computedInputType(this.localItemType)"
            :placeholder="computedInputPlaceholder(this.localItemType)"
            :pattern="computedInputPattern(this.localItemType)"
            :title="computedInputTitle(this.localItemType)"
            isRequired
          />

          <InputWrapper
            v-else
            :labelText="this.computedLabelText(this.localItemType)"
            labelRef="firstName"
            v-model="this.localItemData"
            variant="grey"
            :placeholder="computedInputPlaceholder(this.localItemType)"
            isRequired
          />
        </form>

        <form v-else id="page-item-form" @submit.prevent="savePageItem">
          <InputWrapper
            labelText="Title"
            labelRef="firstName"
            v-model="this.localItemName"
            variant="grey"
            isRequired
          />

          <InputWrapper
            v-if="this.isVariableInput"
            labelText="Field name"
            labelRef="firstName"
            v-model="this.localItemData"
            variant="grey"
            isRequired
          />

          <InputWrapper
            v-if="!this.isVariableInput"
            labelText="Street"
            labelRef="street"
            variant="grey"
            v-model="this.localAddressItemData.street"
            isRequired
          />

          <InputWrapper
            v-if="!this.isVariableInput"
            labelText="Number"
            labelRef="number"
            variant="grey"
            v-model="this.localAddressItemData.number"
          />

          <InputWrapper
            v-if="!this.isVariableInput"
            labelText="Zipcode"
            labelRef="zipcode"
            variant="grey"
            v-model="this.localAddressItemData.zipcode"
            isRequired
          />

          <InputWrapper
            v-if="!this.isVariableInput"
            labelText="City"
            labelRef="city"
            variant="grey"
            v-model="this.localAddressItemData.city"
            isRequired
          />

          <InputWrapper
            v-if="!this.isVariableInput"
            labelText="Country"
            labelRef="country"
            variant="grey"
            v-model="this.localAddressItemData.country"
            isRequired
          />
        </form>

        <template #footer>
          <div class="btn-container">
            <PVButton
              label="Delete"
              icon="pi pi-trash"
              @click="deletePageItem"
              class="btn btn-secondary"
            />
            <PVButton
              label="Save"
              icon="pi pi-check"
              class="btn btn-primary"
              autofocus
              type="submit"
              form="page-item-form"
            />
          </div>
        </template>
      </PVPopup>
    </router-link>
  </li>
</template>

<script lang="ts">
import IconArrange from "@/components/icons/IconArrange.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";

import InputWrapper from "@/components/Input/InputWrapper.vue";

import {
  usePagesStore,
  useBusinessPagesStore,
  useBusinessSettingsStore,
} from "@/stores";

import {
  generateLabelText,
  processUserInput,
  getInputPattern,
  generatePlatformData,
  getInputType,
  generatePlaceholder,
  generateInputTitle,
  getPageId,
} from "@/helpers";

export default {
  data() {
    return {
      pageId: "",
      displayModal: false,
      pagesStore: usePagesStore(),
      businessPagesStore: useBusinessPagesStore(),
      businessSettingsStore: useBusinessSettingsStore(),
      localItemName: this.itemName,
      localItemData: this.itemData,
      localAddressItemData: this.itemData,
      localItemType: this.itemType,
    };
  },
  components: {
    IconArrange,
    IconEdit,
    IconArrowRight,
    InputWrapper,
  },
  props: {
    isAvatar: {
      type: Boolean,
      default: true,
    },
    imageUrl: String,
    itemName: String,
    subtitle: String,
    link: String,
    isEditing: {
      type: Boolean,
      default: false,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    businessId: {
      type: String,
      required: false,
    },
    itemData: String,
    itemId: String,
    itemType: String,
    isBusiness: Boolean,
  },
  created() {
    if (!this.isBusiness) {
      this.pageId = getPageId(this.$route) || "";
    }

    if (this.localItemType === "address") {
      const rawAddress = this.localItemData;
      this.localAddressItemData = this.parseAddressToInput(rawAddress);
    }

    this.resetLocalVariables();
  },
  computed: {
    showFoldableItem() {
      const isCorrectItemType =
        this.itemType === "phone" ||
        this.itemType === "email" ||
        this.itemType === "address";

      if (isCorrectItemType && !this.isEditing) {
        return true;
      } else {
        return false;
      }
    },
    computedLabelText() {
      return (itemType: string) => {
        return generateLabelText(itemType);
      };
    },
    computedLinkUrl() {
      return (itemType: string, itemData: any) => {
        const { linkUrl } = generatePlatformData(itemType, itemData);
        return linkUrl;
      };
    },
    computedInputType() {
      return (itemType: string) => {
        return getInputType(itemType);
      };
    },
    computedInputPattern() {
      return (itemType: string) => {
        return getInputPattern(itemType);
      };
    },
    computedInputPlaceholder() {
      return (itemType: string) => {
        return generatePlaceholder(itemType);
      };
    },
    computedInputTitle() {
      return (itemType: string) => {
        return generateInputTitle(itemType);
      };
    },
    isVariableInput() {
      return (
        this.localItemData.includes("{") && this.localItemData.includes("}")
      );
    },
  },
  methods: {
    resetLocalVariables() {
      this.localItemName = this.itemName;
    },
    parseAddressToInput(addressString: string) {
      const addressParts = addressString.split(";");
      return {
        street: addressParts[0],
        number: addressParts[1],
        zipcode: addressParts[2],
        city: addressParts[3],
        country: addressParts[4],
      };
    },
    formatAddressBeforeSave(addressObject) {
      const { street, number, zipcode, city, country } = addressObject;
      return `${street};${number};${zipcode};${city};${country}`;
    },
    saveChanges() {
      // Method to handle saving changes
      this.$emit("update:item", {
        itemName: this.localItemName,
        itemData: this.localItemData,
      });
    },
    openModal(event) {
      if (!this.isEditing) {
        return;
      }

      event.preventDefault();
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    async deletePageItem() {
      if (this.pagesStore.pages && this.pagesStore.pages[0]) {
        await this.pagesStore
          .removePageItem(this.pageId, this.itemId)
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: "Failed",
              detail: `Something went wrong: ${error}`,
              life: 5000,
            });
          });
      } else {
        if (this.$route.path.includes("business")) {
          await this.deleteBusinessPageItem();
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong with deleting the item`,
            life: 5000,
          });
        }
      }
    },
    async deleteBusinessPageItem() {
      const businessId = this.businessSettingsStore.businessSettings.businessId;

      try {
        await this.businessPagesStore.deletePageTemplateItem(
          businessId,
          this.itemId
        );
        this.displayModal = false;
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Something went wrong: ${error}`,
          life: 5000,
        });
      }
    },
    async savePageItem() {
      this.saveChanges();
      this.displayModal = false;

      const pattern = this.computedInputPattern(this.localItemType);
      const regex = new RegExp(pattern);

      if (!regex.test(this.localItemData) && !this.isBusiness) {
        this.$toast.add({
          severity: "error",
          summary: "Failed to save",
          detail: "Use the right format",
          life: 5000,
        });
        return;
      }

      if (this.localItemType === "address" && !this.isVariableInput) {
        this.localItemData = this.formatAddressBeforeSave(
          this.localAddressItemData
        );
      }

      this.localItemData = processUserInput(
        this.localItemData,
        this.localItemType
      );

      if (this.isBusiness) {
        this.businessPagesStore
          .updatePageTemplateItem(this.businessId, this.itemId, {
            itemName: this.localItemName,
            itemData: this.localItemData,
          })
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Saved",
              detail: `${this.localItemType} is updated`,
              life: 5000,
            });
          });

        this.$emit("update:itemName", this.localItemName);

        return;
      }

      await this.pagesStore
        .updatePageItem(this.pageId, this.itemId, {
          itemName: this.localItemName,
          itemData: this.localItemData,
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Saved",
            detail: `${this.localItemType} is updated`,
            life: 5000,
          });
        });

      await this.pagesStore.getPageItems(this.pageId).then((data) => {
        this.pagesStore.setPageItems(data.rows);
      });
    },
  },
  watch: {
    itemName(newVal) {
      this.localItemName = newVal;
    },
    itemType(newVal) {
      this.localItemType = newVal;
    },
    itemData(newVal) {
      if (this.itemType === "address") {
        const addressArray = newVal.split(";");
        this.localAddressItemData = {
          street: addressArray[0] || "",
          number: addressArray[1] || "",
          zipcode: addressArray[2] || "",
          city: addressArray[3] || "",
          country: addressArray[4] || "",
        };
      } else {
        this.localItemData = newVal;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/list/item.css";
article {
  background-color: var(--color-white);
  padding: 20px 15px 20px 20px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  transition: height 0.2s ease;
  .image {
    height: 35px;
    width: 35px;

    filter: drop-shadow(0px 4px 8px #00000015);
    border-radius: 6px;
  }

  .image img {
    height: 35px;
    width: 35px;
    border-radius: 8px;
  }
}

.action-group {
  display: flex;
  flex-direction: row;
  gap: 0px;

  button {
    padding: 10px 10px;

    display: flex;
  }
}

.editing {
  display: flex;
  svg {
    display: flex;
  }
}

.item-image-and-title {
  width: 65%;
}

.address-variable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-top: 12px;
}
</style>
