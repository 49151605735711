<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.875 0.9375C11.875 1.45703 12.293 1.875 12.8125 1.875H16.8008L8.08594 10.5859C7.71875 10.9531 7.71875 11.5469 8.08594 11.9102C8.45312 12.2734 9.04687 12.2773 9.41016 11.9102L18.1211 3.19922V7.1875C18.1211 7.70703 18.5391 8.125 19.0586 8.125C19.5781 8.125 19.9961 7.70703 19.9961 7.1875V0.9375C19.9961 0.417969 19.5781 0 19.0586 0H12.8125C12.293 0 11.875 0.417969 11.875 0.9375ZM2.8125 1.25C1.25781 1.25 0 2.50781 0 4.0625V17.1875C0 18.7422 1.25781 20 2.8125 20H15.9375C17.4922 20 18.75 18.7422 18.75 17.1875V12.1875C18.75 11.668 18.332 11.25 17.8125 11.25C17.293 11.25 16.875 11.668 16.875 12.1875V17.1875C16.875 17.707 16.457 18.125 15.9375 18.125H2.8125C2.29297 18.125 1.875 17.707 1.875 17.1875V4.0625C1.875 3.54297 2.29297 3.125 2.8125 3.125H7.8125C8.33203 3.125 8.75 2.70703 8.75 2.1875C8.75 1.66797 8.33203 1.25 7.8125 1.25H2.8125Z"
    />
  </svg>
</template>
