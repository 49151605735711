import { defineStore } from "pinia";

import { cdnWrapper, fetchWrapper, BASE_URL } from "@/helpers";

import type { Page, PageItem } from "../types";
import type { BusinessPagesState, UpdatePageItemData, Variable } from "./types";

export const useBusinessPagesStore = defineStore({
  id: "business-pages",
  state: (): BusinessPagesState => ({
    pages: [] as Page[],
    pageTemplateItems: [] as PageItem[],
    pageTemplateItemsCount: Number,
    pageVariables: [] as Variable[],
  }),
  actions: {
    async getPageTemplateItems(businessId: string) {
      const templateItems = await fetchWrapper.get(
        `${BASE_URL}/business/pageTemplate/${businessId}`
      );

      this.pageTemplateItems = templateItems.rows;
      this.pageTemplateItemsCount = templateItems.count;

      return templateItems;
    },
    async createPageTemplateItem(businessId: string, data) {
      return await fetchWrapper.post(
        `${BASE_URL}/business/pageTemplate/${businessId}`,
        data
      );
    },
    async deletePageTemplateItem(businessId: string, itemId: string) {
      await fetchWrapper.delete(
        `${BASE_URL}/business/pageTemplate/${businessId}/${itemId}`
      );

      try {
        // Get the index of the item to be removed
        const itemIndex = this.pageTemplateItems.findIndex(
          (item) => item.itemId === itemId
        );

        // If the item was found in the list
        if (itemIndex !== -1) {
          // Remove the item from the pageTemplateItems array
          this.pageTemplateItems.splice(itemIndex, 1);

          // Update the positions of the remaining items
          this.pageTemplateItems.forEach((item, index) => {
            // If the item comes after the removed item, decrease its position by 1
            if (index >= itemIndex) {
              item.itemPosition = item.itemPosition - 1;
            }
          });

          // Update all the items in the backend using the pinia store
          this.pageTemplateItems.map(async (item) => {
            await this.updatePageTemplateItem(businessId, item.itemId, {
              itemPosition: item.itemPosition,
            });
          });
        }
      } catch (error) {
        console.error("Error removing page item:", error);
      }
    },
    async updatePageTemplateItem(
      businessId: string,
      itemId: string,
      data: UpdatePageItemData
    ) {
      return await fetchWrapper.patch(
        `${BASE_URL}/business/pageTemplate/${businessId}/${itemId}`,
        data
      );
    },
    setPageTemplateItems(pageTemplateItems: PageItem[]) {
      this.pageTemplateItems = pageTemplateItems;
    },
    async getPageVariables(businessId: string) {
      const { rows: variables } = await fetchWrapper.get(
        `${BASE_URL}/business/variable/${businessId}`
      );

      this.pageVariables = variables.reverse();

      return variables;
    },
    async createPageVariable(businessId: string, data: Variable) {
      return await fetchWrapper
        .post(`${BASE_URL}/business/variable/${businessId}`, data)
        .then(() => {
          this.getPageVariables(businessId);
        });
    },
    async updatePageVariable(
      businessId: string,
      variableId: string,
      data: Variable
    ) {
      return await fetchWrapper
        .patch(`${BASE_URL}/page/variables/${variableId}`, data)
        .then(() => {
          this.getPageVariables(businessId);
        });
    },
    async deletePageVariable(variableId: string, businessId: string) {
      return await fetchWrapper
        .delete(`${BASE_URL}/page/variables/${variableId}`)
        .then(() => {
          this.getPageVariables(businessId);
        });
    },
  },
});
