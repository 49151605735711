<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1211_779)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.636364V13.3636C0 13.5324 0.0670452 13.6943 0.186387 13.8136C0.305573 13.9328 0.46717 13.9998 0.635707 14L0.636364 14L0.636997 14H13.3636C13.5324 14 13.6943 13.9329 13.8136 13.8136C13.933 13.6943 14 13.5324 14 13.3636C14 13.1949 13.933 13.033 13.8136 12.9137C13.6943 12.7943 13.5324 12.7273 13.3636 12.7273H1.27273V0.636364C1.27273 0.46759 1.20568 0.305728 1.08634 0.186387C0.966999 0.0670452 0.805138 0 0.636364 0C0.467589 0 0.305728 0.0670452 0.186387 0.186387C0.0670452 0.305728 0 0.46759 0 0.636364ZM12.0676 8.3724C12.1865 8.49127 12.3471 8.55902 12.5152 8.56122C12.6833 8.55902 12.8439 8.49127 12.9627 8.3724C13.0816 8.25353 13.1493 8.09295 13.1515 7.92486V4.8788C13.1493 4.7107 13.0816 4.55012 12.9627 4.43125C12.8439 4.31238 12.6833 4.24463 12.5152 4.24243H9.54548C9.37671 4.24243 9.21485 4.30948 9.09551 4.42882C8.97616 4.54816 8.90912 4.71002 8.90912 4.8788C8.90912 5.04757 8.97616 5.20943 9.09551 5.32877C9.21485 5.44811 9.37671 5.51516 9.54548 5.51516H10.9794L8.69699 7.79758L6.60124 5.70182C6.48192 5.58265 6.32018 5.51571 6.15154 5.51571C5.9829 5.51571 5.82116 5.58265 5.70184 5.70182L3.15639 8.24727C3.04398 8.36791 2.98279 8.52746 2.98569 8.69232C2.9886 8.85718 3.05539 9.01448 3.17198 9.13107C3.28858 9.24767 3.44587 9.31445 3.61073 9.31736C3.7756 9.32027 3.93515 9.25907 4.05578 9.14667L6.15154 7.05091L8.2473 9.14667C8.30605 9.20616 8.37609 9.25331 8.45332 9.28537C8.53054 9.31742 8.61338 9.33373 8.69699 9.33333C8.7806 9.33373 8.86345 9.31742 8.94067 9.28537C9.0179 9.25331 9.08794 9.20616 9.14669 9.14667L11.8788 6.41454V7.92486C11.881 8.09295 11.9488 8.25353 12.0676 8.3724Z"
        fill="#222222"
      />
    </g>
    <defs>
      <clipPath id="clip0_1211_779">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
