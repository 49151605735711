<script lang="ts">
import NavigationMenu from "@/components/business/NavigationMenu.vue";

import { IS_DEVELOPMENT } from "@/helpers";

export default {
  components: {
    NavigationMenu,
  },
  computed: {
    showNavigation() {
      const route = this.$route.path;

      const isRouteStartingWithBusiness = route.startsWith("/business");
      const dontShowNavRoutes = [
        "/business/login",
        "/business/sign-up",
        "/business/forgot-password",
      ];

      if (isRouteStartingWithBusiness && !dontShowNavRoutes.includes(route)) {
        return true;
      }

      return false;
    },
  },
  methods: {
    publicPageCheck() {
      const fullPath = this.$route.fullPath;
      const host = window.location.host;
      const isCurrentHostConnectflow = host === "connectflow.app";
      const isCurrentHostTest = host === "frontend-test.connectflow.app";

      const cardOrPageRegex = /^\/[pc]\/[a-zA-Z0-9]+(?:\/save)?$/;
      const isPathCardOrPage = cardOrPageRegex.test(fullPath);

      if (IS_DEVELOPMENT || isCurrentHostTest) {
        return;
      }

      if (!isPathCardOrPage) {
        if (isCurrentHostConnectflow) {
          return;
        }

        const fullConnectflowUrl = "https://connectflow.app" + fullPath;
        window.location.href = fullConnectflowUrl;

        return;
      }

      return;
    },
  },
};
</script>

<template>
  <PVConfirmDialog class="confirm-dialog" />
  <PVToast />

  <div class="business-container" v-if="showNavigation">
    <NavigationMenu class="menu" />
    <RouterView class="business-content"></RouterView>
  </div>

  <div class="standard-container" v-else>
    <RouterView />
  </div>
</template>

<style lang="scss" scoped>
.business-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.menu {
  width: 75px;
  animation: move-left-300 0.8s cubic-bezier(0, 0, 0, 1) forwards;

  @media screen and (min-width: 1000px) {
    width: 250px;
    flex-shrink: 1;
  }
}

.business-content {
  flex: 1;
  padding: 30px 35px;

  @media screen and (max-width: 487px) {
    padding-bottom: 120px;
  }

  @media screen and (min-width: 487px) {
    margin-left: 100px;
  }

  @media screen and (min-width: 1000px) {
    margin-left: 250px;
  }
}
</style>
