import { defineStore } from "pinia";

import { fetchWrapper, BASE_URL } from "@/helpers";

import Cookies from "universal-cookie";

import type {
  BusinessUser,
  BusinessUserState,
  UpgradeBusinessUser,
  UpdateBusinessUser,
} from "./types";

const cookies = new Cookies();

export const useBusinessUsersStore = defineStore({
  id: "business-users",
  state: (): BusinessUserState => ({
    businessUsers: [] as BusinessUser[],
  }),
  actions: {
    async upgradeUserToBusiness(
      accountId: string,
      userData: UpgradeBusinessUser
    ): Promise<BusinessUser> {
      try {
        return await fetchWrapper.patch(
          `${BASE_URL}/users/upgradeToBusiness/${accountId}`,
          userData
        );
      } catch (error) {
        console.error("Error upgrading user to business:", error);
        throw error;
      }
    },
    async addUserToBusiness(
      businessId: number,
      userData: BusinessUser
    ): Promise<void> {
      const { fname, lname, functionUser, role, email, phoneNumber } = userData;
      return await fetchWrapper.post(
        `${BASE_URL}/users/addUser/${businessId}`,
        {
          fname,
          lname,
          functionUser,
          role,
          email,
          phoneNumber,
        }
      );
    },
    async getBusinessUsers(businessId: string): Promise<void> {
      const { rows } = await fetchWrapper.get(
        `${BASE_URL}/users/usersList/${businessId}`
      );

      this.businessUsers = rows;

      return rows;
    },
    async getBusinessUser(userId: string): Promise<BusinessUser> {
      return await fetchWrapper.get(`${BASE_URL}/business/user/${userId}`);
    },
    async updateBusinessUser(userId: string, data: UpdateBusinessUser) {
      await fetchWrapper.post(`${BASE_URL}/business/user/${userId}`, data);
    },
    async deleteBusinessUser(userId: string) {
      await fetchWrapper.delete(`${BASE_URL}/business/user/${userId}`);
    },
    async updateVariableValue(userId: string, variableId: string, data) {
      return await fetchWrapper.patch(
        `${BASE_URL}/business/variable/${userId}/${variableId}`,
        data
      );
    },
    async uploadBusinessUserProfileImage(file: File, userId: string) {
      const formData = new FormData();
      formData.append("image", file);

      const authHeader = {
        Authorization: "jwt " + cookies.get("token"),
      };

      try {
        const response = await fetch(
          `${BASE_URL}/business/pageimage/upload/${userId}`,
          {
            method: "PATCH",
            body: formData,
            headers: {
              ...authHeader,
            },
          }
        );

        if (response.ok) {
          // Successfully uploaded
          return await response.json();
        } else {
          // Failed to upload, throwing an error
          const errorData = await response.json();
          throw new Error(
            `Error uploading image: ${JSON.stringify(errorData)}`
          );
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
  },
});
