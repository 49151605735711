<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 576 379"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="189.14"
      cy="189.14"
      r="165.14"
      stroke="black"
      stroke-width="48"
    />
    <circle
      cx="386.86"
      cy="189.14"
      r="165.14"
      stroke="black"
      stroke-width="48"
    />
  </svg>
</template>
