import { defineStore } from "pinia";
import { fetchWrapper, BASE_URL } from "@/helpers";

export const useSuperAdminStore = defineStore({
  id: "super-admin",
  actions: {
    async generateCards(
      amount: number,
      cardType: string,
      cardCategoryName: string
    ) {
      const csvData = await fetchWrapper.post(`${BASE_URL}/card/${amount}`, {
        cardType,
        cardCategoryName,
      });

      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.download = "data.csv";
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});
