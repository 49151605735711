<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM20 12C20 12.6906 19.9125 13.3608 19.748 14H16.41C16.469 13.3538 16.5 12.6849 16.5 12C16.5 11.3151 16.469 10.6462 16.41 10H19.748C19.9125 10.6392 20 11.3094 20 12ZM16.1256 8H18.9297C18.046 6.47231 16.6716 5.26398 15.0221 4.59049C15.4904 5.53438 15.8689 6.69453 16.1256 8ZM8.97794 4.59049C7.32837 5.26398 5.95398 6.47231 5.07026 8H7.87444C8.13111 6.69453 8.50958 5.53438 8.97794 4.59049ZM7.59002 10H4.25204C4.08751 10.6392 4 11.3094 4 12C4 12.6906 4.08751 13.3608 4.25204 14H7.59002C7.53099 13.3538 7.5 12.6849 7.5 12C7.5 11.3151 7.53099 10.6462 7.59002 10ZM7.87444 16H5.07026C5.95398 17.5277 7.32837 18.736 8.97794 19.4095C8.50958 18.4656 8.13111 17.3055 7.87444 16ZM15.0221 19.4095C16.6716 18.736 18.046 17.5277 18.9297 16H16.1256C15.8689 17.3055 15.4904 18.4656 15.0221 19.4095ZM14.5 12C14.5 12.6943 14.4652 13.3635 14.4008 14H9.59918C9.53484 13.3635 9.5 12.6943 9.5 12C9.5 11.3057 9.53484 10.6365 9.59918 10H14.4008C14.4652 10.6365 14.5 11.3057 14.5 12ZM14.0822 8C13.8907 7.14167 13.6428 6.38215 13.3581 5.74966C13.0204 4.99913 12.6701 4.51413 12.3818 4.24316C12.2431 4.11273 12.1393 4.05118 12.079 4.02377C12.031 4.00192 12.008 4.00012 12.0017 4H11.9983C11.992 4.00012 11.969 4.00192 11.921 4.02377C11.8607 4.05118 11.7569 4.11273 11.6182 4.24316C11.3299 4.51413 10.9796 4.99913 10.6419 5.74966C10.3572 6.38215 10.1093 7.14167 9.91779 8H14.0822ZM9.91779 16C10.1093 16.8583 10.3572 17.6179 10.6419 18.2503C10.9796 19.0009 11.3299 19.4859 11.6182 19.7568C11.7569 19.8873 11.8607 19.9488 11.921 19.9762C11.9689 19.998 11.9919 19.9999 11.9983 20H12.0017C12.0081 19.9999 12.0311 19.998 12.079 19.9762C12.1393 19.9488 12.2431 19.8873 12.3818 19.7568C12.6701 19.4859 13.0204 19.0009 13.3581 18.2503C13.6428 17.6179 13.8907 16.8583 14.0822 16H9.91779Z"
      fill="black"
    />
  </svg>
</template>
