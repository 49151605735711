export const isValidHexColor = (color: string) => {
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return regex.test(color);
};

export const isValidOpacity = (opacity: string) => {
  const regex = /^\d{1,3}%$/;
  const opacityValue = parseInt(opacity, 10);
  return regex.test(opacity) && opacityValue >= 1 && opacityValue <= 100;
};
