import { defineStore } from "pinia";
import { fetchWrapper, BASE_URL } from "@/helpers";

import type { CardsState, Card } from "./types";

export const useCardsStore = defineStore({
  id: "cards",
  state: (): CardsState => ({
    cards: [] as Card[],
  }),
  actions: {
    resetCardsData() {
      this.card = [];
    },
    async getCardData(cardId: string) {
      return await fetchWrapper.get(`${BASE_URL}/card/${cardId}`);
    },
    async getAllCards(accountId: string) {
      const response = await fetchWrapper.get(
        `${BASE_URL}/card/getCards/${accountId}`
      );

      if (response.success) {
        const newCards = response.data.rows;

        for (const card of newCards) {
          this.cards.push(card);
        }
      }
    },
    async createVirtualCard(accountId: string, pageId: string) {
      const cardResponse = await fetchWrapper.post(
        `${BASE_URL}/card/addVirtualCard/${accountId}`
      );

      const cardId = cardResponse.data.uuid;

      await this.linkCardToPage(pageId, cardId);

      return cardId;
    },
    async linkCardToPage(pageId: string, cardId: string, accountId?: string) {
      const response = await fetchWrapper.patch(`${BASE_URL}/card/${cardId}`, {
        pageId,
        accountId,
      });

      return response;
    },
    async deleteCard(cardId: string) {
      await fetchWrapper.delete(`${BASE_URL}/card/${cardId}`);
    },
    async getCardImage(cardTypeId: string) {
      const { ImageUrl } = await fetchWrapper.get(
        `${BASE_URL}/cards/${cardTypeId}`
      );

      return ImageUrl;
    },
  },
});
