<script lang="ts">
export default {
  data() {
    return {
      showError: false,
    };
  },
  props: {
    isHidden: {
      default: false,
      type: Boolean,
    },
    labelText: {
      required: true,
      type: String,
    },
    labelRef: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    },
    variant: {
      type: String,
    },
    inputType: {
      default: "text",
      type: String,
    },
    isRequired: {
      default: false,
      type: Boolean,
    },
    type: {
      default: "text",
      type: String,
    },
    maxWidth: {
      default: "max-content",
      type: String,
    },
    isDisabled: {
      default: false,
    },
    pattern: String,
    title: String,
    inputMode: String,
  },
  emits: ["update:modelValue"],
  methods: {
    onInput(event: Event) {
      const target = event.target as HTMLInputElement | null;
      if (target !== null) {
        this.$emit("update:modelValue", target.value);

        const regex = new RegExp(this.pattern);
        const isValid = regex.test(this.modelValue);

        if (!isValid) {
          this.showError = true;
        } else {
          this.showError = false;
        }
      }
    },
  },
};
</script>

<template>
  <div class="input-wrapper input-inline-label">
    <label :for="labelRef">{{ labelText }}</label>
    <input :id="labelRef" :aria-describedby="labelRef" :placeholder="placeholder" :class="{ grey: variant === 'grey' }"
      :required="isRequired ? true : false" :value="modelValue" :type="type" :pattern="pattern" :title="title"
      @input="onInput" class="input-text p-inputtext" :disabled="isDisabled" :maxlength="maxWidth"
       />
    <span v-if="showError">{{ title }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/input.css";

span {
  color: #222;
  font-weight: 500;
  margin-left: 8px;
  font-size: 12px;
  padding-top: 5px;
}
</style>