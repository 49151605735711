<script lang="ts">
export default {
  props: {
    labelText: {
      required: true,
      type: String,
    },
    labelRef: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    },
    maxlength: {
      type: String,
    },
    variant: {
      type: String,
    },
    inputType: {
      default: "text",
      type: String,
    },
    isRequired: {
      default: false,
      type: Boolean,
    },
    rows: {
      type: Number,
    },
    cols: {
      default: 30,
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onInput(event: Event) {
      const target = event.target as HTMLInputElement | null;
      if (target !== null) {
        this.$emit("update:modelValue", target.value);
      }
    },
  },
};
</script>

<template>
  <div class="input-wrapper input-inline-label">
    <label :for="labelRef">{{ labelText }}</label>
    <PVTextArea
      :id="labelRef"
      @input="onInput"
      :value="modelValue"
      :aria-describedby="labelRef"
      class="input-text text-area-input"
      :placeholder="placeholder"
      :class="{ grey: variant === 'grey' }"
      :required="isRequired ? true : false"
      :cols="cols"
      :rows="rows"
      :maxlength="maxlength"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/input.css";

.text-area-input {
  resize: none;
}
</style>
