import Cookies from "universal-cookie";

const cookies = new Cookies();

interface ResponseData {
  [key: string]: any;
}

interface ResponseWithData extends Response {
  data: ResponseData;
}

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export const cdnWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
};

function request(method: HttpMethod) {
  return async (url: string, body?: object): Promise<ResponseData> => {
    const requestOptions: any = {
      method,
      headers: authHeader(),
    };

    if (body) {
      requestOptions.body = body;
    }

    try {
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseData: ResponseWithData = await response.json();

      return responseData;
    } catch (error) {
      // Handle the error here
      console.error("Request failed:", error);
      throw error;
    }
  };
}

function authHeader(): { [key: string]: string } {
  const accessToken = cookies.get("token");

  return { Authorization: `jwt ${accessToken}` };
}
